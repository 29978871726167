<template>
  <b-container fluid class="mt-0">
    <b-table
      responsive
      thead-class="text-center bg-light-grey "
      head-variant="light"
      bordered
      small
      show-empty
      outlined
      :items="vehicles"
      :fields="fields"
      stacked="sm"
    >
      <template v-slot:cell(image)="{ item }">
        <div class="mx-auto">
          <vehicle-card-gallery
            class=""
            :images="formatImages(getImagesOfVehicle(item))"
            :showThumbnail="false"
            :typeList="'list'"
            style="max-width: 15rem !important"
          />
          <card-title :vehicle="item" style="max-width: 15rem !important" />
        </div>
      </template>
      <template v-slot:cell(vinNumber)="{ item }">
        <router-link
          v-if="item.vinNumber.vinNumber"
          :to="getVehiclePageUrl(item)"
        >
          <div>
            <i>
              {{ item.vinNumber.vinNumber }}
            </i>
          </div>
        </router-link>
      </template>
      <template v-slot:cell(year)="{ item }">
        {{ item.vinNumber.year }}
      </template>
      <template v-slot:cell(make)="{ item }">
        {{ item.vinNumber.make }}
      </template>
      <template v-slot:cell(model)="{ item }">
        {{ item.vinNumber.model }}
      </template>

      <template v-slot:cell(miles)="{ item }">
        {{ item.vehicleInformation.miles | formatNumber }} Mi
      </template>

      <template v-slot:cell(cr)="{ item }">
        <CRButton
          v-if="item.vehicleHasConditionalReports.length > 0"
          :vehicle="item"
          class="mx-auto"
        />
      </template>
      <template v-slot:cell(actions)="{ item }">
        <div
          class="mb-2 pt-2 text-center"
          v-if="item.vehicleCostDetail && item.vehicleCostDetail.price"
        >
          <strong>
            {{ item.vehicleCostDetail.price | currency }}
          </strong>
        </div>
        <div
          v-if="vehicleAcceptOffer(item) || item.status === 'Sold'"
          class="m"
        >
          <img
            src="@/assets/img/TOOLATE.png"
            alt="sold-image"
            class="img-fluid"
          />
        </div>
        <div v-else>
          <b-row v-if="viewOffers" class="">
            <b-col class="mb-1">
              <b-button
                variant="purple-blue"
                block
                @click="showVehicleOffers(item)"
                class="shadow"
              >
                View Offers
              </b-button>
            </b-col>
          </b-row>

          <b-row class="" v-else-if="!isHome && user">
            <b-col>
              <b-button
                v-if="
                  item.status !== 'Inactive' &&
                  (userIsAdmin || (userIsAgent && item.usersId === user.id))
                "
                variant="purple-blue"
                block
                class=""
                @click="editVehicle(item.vinNumber.vinNumber)"
              >
                <b-icon icon="pencil" />
              </b-button>
            </b-col>
          </b-row>
          <img
            v-if="item.vehicleFlags.wo != 0 && userIsAdmin"
            class="img-responsive mt-1"
            height="25"
            src="@/assets/icon/work-order-icon.png"
            v-b-tooltip.hover
            title="This vehicle has had a work order"
          />
          <b-row
            v-if="
              !isHome &&
              !isActiveOffer &&
              !isExpiredOffer &&
              (userHasOffer || item.lastOffer) &&
              userHasCompany
            "
            class=""
          >
            <b-col>
              <b-button
                variant="purple-blue"
                block
                size="sm"
                @click="changeOfferTab(item.id)"
              >
                <h5 class="">
                  Current Offer:
                  <span v-if="item.lastOffer">
                    {{ item.lastOffer.offerPrice | currency }}
                  </span>
                  <span v-else-if="userHasOffer">
                    {{ currentOffer | currency }}
                  </span>
                </h5>
              </b-button>
            </b-col>
          </b-row>
        </div>
        <MakeAnOffer
          v-if="
            !isHome &&
            !userHasOffer &&
            !item.lastOffer &&
            !isExpiredOffer &&
            !isActiveOffer &&
            !userIsAdmin
          "
          :vehicleId="item.id"
          :price="item.vehicleCostDetail.price"
          :userPending="!userHasCompany"
          @setUserHasOffer="setUserHasOffer($event)"
          :stacked="true"
          :vehicle="item"
          @refresh="refreshVehicles()"
        />
      </template>
    </b-table>
  </b-container>
</template>

<script>
import CRButton from "./VehicleCard/CRButton.vue";
import VehicleCardGallery from "./VehicleCard/VehicleCardGallery.vue";
import MakeAnOffer from "./components/MakeAnOffer";
import { SET_VEHICLES_FILTERS } from "./actions";

import {
  SHOW_OFFERS_SIDEBAR,
  SHOW_OFFER_SIDEBAR,
  SET_OFFER_SIDEBAR,
  GET_OFFERS_BY_VEHICLE,
} from "@/modules/admin/vehicles/offers/actions";
import { listVehiclesMixin } from "./mixins";
import { generalMixin } from "@/modules/mixin";
import CardTitle from "./VehicleCard/CardTitle.vue";

export default {
  components: { CRButton, VehicleCardGallery, MakeAnOffer, CardTitle },
  name: "VehiclesListView",
  props: {
    vehicles: {
      type: Array,
      required: true,
    },
    viewOffers: {
      type: Boolean,
      default: false,
    },
    isActiveOffer: {
      type: Boolean,
      default: false,
    },
    isExpiredOffer: {
      type: Boolean,
      default: false,
    },
    refresh: {
      type: Function,
      default: () => {},
    },
  },
  mixins: [listVehiclesMixin, generalMixin],
  data() {
    return {
      fields: [
        {
          key: "image",
          label: "",
          class: "text-center ",
        },
        {
          key: "vinNumber",
          label: "VIN",
          class: "text-center",
        },
        {
          key: "year",
          label: "Year",
          class: "text-center",
        },
        {
          key: "make",
          label: "Make",
          class: "text-center",
        },
        {
          key: "model",
          label: "Model",
          class: "text-center",
        },

        {
          key: "miles",
          label: "Miles",
          class: "text-center",
        },

        {
          key: "cr",
          label: "CR",
          class: "text-center",
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-center",
        },
      ],
      imageProps: {
        height: "100px",
        width: "100px",
        block: true,
        rounded: true,
      },
      userHasOffer: false,
    };
  },
  computed: {
    isHome() {
      return this.$route.name === "home" ? true : false;
    },
  },
  methods: {
    showVehicleDetails(vehicle) {
      this.$emit("showVehicleDetails", vehicle);
    },

    formatImages(images) {
      return images.map((image) => {
        return {
          id: image.photo,
          src: image.photo,
          largeViewSrc: image.photo,
          thumbnail: image.photoThumbnail || image.photo,
        };
      });
    },
    getImagesOfVehicle(vehicle) {
      let images = [];
      if (vehicle.vehicleHasParts) {
        vehicle.vehicleHasParts.forEach((part) => {
          images.push({
            photo: part.photo,
            photoThumbnail: part.photoThumbnail,
          });
        });
      }
      if (vehicle.vehicleExternalImages) {
        vehicle.vehicleExternalImages.forEach((image) => {
          images.push({
            photo: image.photo,
            photoThumbnail: image.photoThumbnail,
          });
        });
      }
      return images;
    },

    userOffer() {
      return {};
    },
    lastOfferUser(vehicle) {
      return vehicle.lastOfferUser;
    },
    lastOfferAdmin(vehicle) {
      return vehicle.lastOfferAdmin;
    },

    vehicleDetailsSideBar() {
      return this.$store.state.adminStore.vehicles.listStore.componentsStore
        .vehicleDetailsSideBar;
    },
    offerSideBar() {
      return this.$store.state.adminStore.vehicles.offersStore.offerSideBar;
    },
    vehicleAcceptOffer(vehicle) {
      return this.$store.state.adminStore.vehicles.offersStore.vehiclesSold.vehicles.find(
        (v) => v.vehicleId === vehicle.id
      );
    },
    showVehicleOffers(vehicle) {
      this.$store.commit(SHOW_OFFER_SIDEBAR, false);
      this.$store.commit(SHOW_OFFERS_SIDEBAR, false);
      this.getVehicleOffers(vehicle);
      setTimeout(() => {
        this.$store.commit(SHOW_OFFER_SIDEBAR, true);
      }, 1000);
    },
    getVehicleOffers(vehicle) {
      this.$store
        .dispatch(GET_OFFERS_BY_VEHICLE, vehicle.vehicleId)
        .then(() => {
          // SET VEHICLE DATA TO SHOW IN SIDEBAR
          this.vehicle.isActiveOffer = this.isActiveOffer;
          this.$store.commit(SET_OFFER_SIDEBAR, this.vehicle);

          this.markNoticationAsRead();
        })

        .catch(() => {
          this.toastMessage(
            "The vehicle offers could not be obtained",
            "Warning",
            "warning"
          );
        });
    },
    changeOfferTab(vehicleId) {
      this.$emit("resetFilters");
      this.$store.commit(SET_VEHICLES_FILTERS, { fields: { vehicleId } });
      this.$emit("changeTabNumber");
    },
    editVehicle(vin) {
      this.$store.commit("SET_IS_SHOWING_VIN_INFORMATION_SIDEBAR", {
        status: true,
        data: vin,
      });
    },
    setUserHasOffer(offer) {
      this.userHasOffer = true;
      this.currentOffer = offer;
    },
    calculatedCost(vehicleCost, fx = null, vehicleCostUsd) {
      let cost = vehicleCost;
      if (fx) {
        if (vehicleCost === 0) {
          return cost;
        } else {
          return (cost + 2500) / fx;
        }
      }
      return vehicleCostUsd;
    },
    refreshVehicles() {
      this.refresh();
    },
    getVehiclePageUrl(vehicle) {
      return (
        "/vehicle/" +
        vehicle.stockNumber +
        "-" +
        vehicle.vinNumber.make +
        "-" +
        vehicle.vinNumber.model
      );
    },
  },
};
</script>

<style></style>
