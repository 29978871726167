<template>
  <b-modal
    modal-class="modal-fullscreen"
    centered
    no-close-on-backdrop
    scrollable
    id="conditional-reports-modal"
    ref="modal"
    title="Condition Reports"
    @show="getConditionalReports(true, vehicle)"
    @hidden="resetForm()"
  >
    <CRRegistrationForm :vehicle="vehicle" />

    <b-list-group v-if="conditionalReports.status == 'fetching'" class="mt-2">
      <b-list-group-item class="text-center">
        <b-spinner variant="blue" />
      </b-list-group-item>
    </b-list-group>

    <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
      <b-list-group-item
        v-if="
          conditionalReports.data.data && !conditionalReports.data.data.length
        "
        class="text-center"
      >
        <b>No condition reports to show</b>
      </b-list-group-item>
      <b-list-group-item
        v-for="(report, i) in conditionalReports.data.data"
        :key="i"
        class="flex-column align-items-start"
        tag="li"
      >
        <b-row>
          <b-col cols="10">
            <small class="text-muted">
              {{ report.date | formatDateTime }} - {{ report.user.lastName }}
              {{ report.user.firstName }}
            </small>
          </b-col>
          <b-col cols="2">
            <div class="text-right">
              <span
                class="dot"
                :class="[report.status ? 'dot-success' : 'dot-danger']"
              />
            </div>
          </b-col>
        </b-row>
        <p class="mt-2 mb-1">
          <span
            class="mt-2 mb-1 cursor-pointer"
            @click="showConditionalReport(report.url)"
          >
            Open Condition Report
          </span>
        </p>
      </b-list-group-item>
    </b-list-group>

    <template v-slot:modal-footer="{ close }">
      <b-pagination
        :value="conditionalReports.data.currentPage"
        :total-rows="conditionalReports.data.total"
        :per-page="conditionalReports.data.perPage"
        @change="changePage($event)"
        class="mt-2"
      />
      <div class="justify-content-end">
        <b-button
          variant="outline-secondary"
          class="mr-2"
          @click="() => close()"
        >
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import { SET_VEHICLE_CONDITIONAL_REPORTS_FILTERS } from "../actions";
import { superAdminMixin } from "../../mixins";
import CRRegistrationForm from "./RegistrationForm";
export default {
  components: { CRRegistrationForm },
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
      conditionalReports: (state) =>
        state.superAdminStore.componentStore.conditionalReports,
    }),
  },
  methods: {
    resetForm() {
      console.log("Reset form");
    },
    showConditionalReport(report) {
      window.open(report, "_blank");
    },
    changePage(page) {
      this.$store.commit(SET_VEHICLE_CONDITIONAL_REPORTS_FILTERS, {
        fields: { page },
      });
      this.getConditionalReports(false, this.vehicle);
    },
  },
};
</script>

<style scoped>
@import "../styles/modals.css";
</style>
