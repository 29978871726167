import * as constants from "./actions";
import axios from "@/plugins/axios.js";
import componentsStore from "./components/store";

function getDefaultState() {
  return {
    vehicles: {
      status: null,
      data: [],
      error: null,
      filters: {
        agent: null,
        client: null,
        vinNumber: null,
        location: null,
        search: null,
        make: null,
        model: null,
        trim: null,
        transmissionType: null,
        releaseDate1: null,
        releaseDate2: null,
        year1: null,
        year2: null,
        mile1: null,
        mile2: null,
        price1: null,
        price2: null,
        status: null,
        trackingStatus: null,
        mainOfferId: null,
        new: null,
        vehicleId: null,
        resourcesPerPage: 33,
        page: 1,
        saleDateFrom: null,
        saleDateTo: null,
        published: 1,
        noPublished: 1
      },
    },
    filterAction: {
      action: "GET_VEHICLES",
    },
    vehicleFilters: {
      status: null,
      data: [],
      models: {
        status: null,
        data: [],
        error: null,
      },
      trims: {
        status: null,
        data: [],
        error: null,
      },
      error: null,
      filters: {
        make: null,
        model: null,
      },
    },
    notificationRead: { status: null, data: [], error: null },
  };
}

const actions = {
  [constants.GET_VEHICLES]: async ({ commit, state }) => {
    try {
      commit(constants.GET_VEHICLES);
      const payload = Object.entries(state.vehicles.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );

      const response = await axios.get(`vehicle`, { params: payload });
      commit(constants.GET_VEHICLES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLES_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_VEHICLE_FILTERS]: async ({ commit }) => {
    try {
      commit(constants.GET_VEHICLE_FILTERS);
      const response = await axios.get(`vehicle/getFiltersCanam`);
      commit(constants.GET_VEHICLE_FILTERS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLE_FILTERS_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_VEHICLE_MODELS]: async ({ commit }, make) => {
    try {
      commit(constants.GET_VEHICLE_MODELS);
      const response = await axios.get(`vehicle/getFiltersCanam`, {
        params: make,
      });
      commit(constants.GET_VEHICLE_MODELS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLE_MODELS_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_VEHICLE_TRIMS]: async ({ commit }, model) => {
    try {
      commit(constants.GET_VEHICLE_TRIMS);
      const response = await axios.get(`vehicle/getFiltersCanam`, {
        params: model,
      });
      commit(constants.GET_VEHICLE_TRIMS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLE_TRIMS, error);
      throw error.response;
    }
  },
  [constants.MARK_NOTIFICATION_AS_READ]: async ({ commit }, model) => {
    try {
      commit(constants.MARK_NOTIFICATION_AS_READ);
      const response = await axios.put(
        `vehicle/readOfferNotification?vehicleId=${model.id}`
      );
      commit(constants.MARK_NOTIFICATION_AS_READ_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.MARK_NOTIFICATION_AS_READ, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_VEHICLES]: (state) => {
    state.vehicles.status = "fetching";
    state.vehicles.error = null;
  },
  [constants.GET_VEHICLES_SUCCESS]: (state, data) => {
    state.vehicles.status = "success";
    state.vehicles.data = data;
    state.vehicles.error = null;
  },
  [constants.GET_VEHICLES_ERROR]: (state, error) => {
    state.vehicles.status = "error";
    state.vehicles.error = error;
  },
  [constants.GET_VEHICLE_FILTERS]: (state) => {
    state.vehicleFilters.status = "fetching";
    state.vehicleFilters.error = null;
  },
  [constants.GET_VEHICLE_FILTERS_SUCCESS]: (state, data) => {
    state.vehicleFilters.status = "success";
    state.vehicleFilters.data = data;
    state.vehicleFilters.error = null;
  },
  [constants.GET_VEHICLE_FILTERS_ERROR]: (state, error) => {
    state.vehicleFilters.status = "error";
    state.vehicleFilters.error = error;
  },
  [constants.SET_VEHICLES_FILTERS]: (state, filters) => {
    if (filters.reset) {
      state.vehicles.filters = {
        agent: null,
        client: null,
        vinNumber: null,
        location: null,
        search: null,
        make: null,
        model: null,
        trim: null,
        transmissionType: null,
        releaseDate1: null,
        releaseDate2: null,
        year1: null,
        year2: null,
        mile1: null,
        mile2: null,
        price1: null,
        price2: null,
        status: null,
        trackingStatus: null,
        mainOfferId: null,
        new: null,
        vehicleId: null,
        resourcesPerPage: 33,
        page: 1,
      };
    }
    if (filters.fields) {
      state.vehicles.filters = {
        ...state.vehicles.filters,
        ...filters.fields,
      };
    }
  },
  [constants.SET_VEHICLE_FILTER_ACTION]: (state, action) => {
    state.filterAction.action = action ? action : "GET_VEHICLES";
  },
  [constants.GET_VEHICLE_MODELS]: (state) => {
    state.vehicleFilters.models.status = "fetching";
    state.vehicleFilters.models.error = null;
  },
  [constants.GET_VEHICLE_MODELS_SUCCESS]: (state, data) => {
    state.vehicleFilters.models.status = "success";
    state.vehicleFilters.models.data = data;
    state.vehicleFilters.models.error = null;
  },
  [constants.GET_VEHICLE_MODELS_ERROR]: (state, error) => {
    state.vehicleFilters.models.status = "error";
    state.vehicleFilters.models.error = error;
  },
  [constants.CLEAR_VEHICLE_MODELS]: (state) => {
    state.vehicleFilters.models.status = null;
    state.vehicleFilters.models.data = [];
    state.vehicleFilters.models.error = null;
  },
  [constants.GET_VEHICLE_TRIMS]: (state) => {
    state.vehicleFilters.trims.status = "fetching";
    state.vehicleFilters.trims.error = null;
  },
  [constants.GET_VEHICLE_TRIMS_SUCCESS]: (state, data) => {
    state.vehicleFilters.trims.status = "success";
    state.vehicleFilters.trims.data = data;
    state.vehicleFilters.trims.error = null;
  },
  [constants.GET_VEHICLE_TRIMS_ERROR]: (state, error) => {
    state.vehicleFilters.trims.status = "error";
    state.vehicleFilters.trims.error = error;
  },
  [constants.CLEAR_VEHICLE_TRIMS]: (state) => {
    state.vehicleFilters.trims.status = null;
    state.vehicleFilters.trims.data = [];
    state.vehicleFilters.trims.error = null;
  },

  [constants.MARK_NOTIFICATION_AS_READ_SUCCESS]: (state, data) => {
    state.notificationRead.status = "success";
    state.notificationRead.data = data;
    state.notificationRead.error = null;
  },
  [constants.MARK_NOTIFICATION_AS_READ_ERROR]: (state, error) => {
    state.notificationRead.status = "error";
    state.notificationRead.error = error;
  },
  [constants.MARK_NOTIFICATION_AS_READ]: (state) => {
    state.notificationRead.status = null;
    state.notificationRead.data = [];
    state.notificationRead.error = null;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
  modules: {
    componentsStore,
  },
};
