<template>
  <b-button
    :disabled="crFileSLoading"
    class=""
    variant="white"
    v-b-tooltip.hover
    title="Open CR and full details"
    @click="getCRFile(vehicle.id)"
  >
    <img
      src="../../../../../assets/img/logos/checkSolutions.png"
      alt="Manheim"
      class="cursor-pointer"
      width="50"
    />
    <br />
    CR
    <b-spinner v-if="crFileSLoading" small label="Loading..."></b-spinner>
  </b-button>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CRButton",
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      crFileSLoading: (state) =>
        state.adminStore.vehicles.carRegistry.crFile.status === "fetching",
    }),
  },
  methods: {
    openCRPageModal() {
      window.open(this.vehicle?.vehicleHasConditionalReports[0]?.url, "_blank");
    },
    getCRFile(vehicleId) {
      this.$store
        .dispatch("GET_CR_FILE", { id: vehicleId })
        .then((response) => {
          console.log(response);
          if (!response.error) {
            const url = response.url;
            const link = document.createElement("a");
            /* open in blank */
            link.target = "_blank";
            link.setAttribute("href", url);
            link.setAttribute("download", "cr.pdf");
            document.body.appendChild(link);
            link.click();
          }
        });
    },
  },
};
</script>

<style></style>
