<template>
  <div class="container-fluid">
    <b-row>
      <b-col>
        <h4 class="ml-3">Active</h4>
      </b-col>
    </b-row>
    <div class="pt-4">
      <TableBase
        :items="users"
        :fields="fields"
        :options="options"
        :busy="isBusy"
      />
    </div>

    <b-modal
      ref="detailsModal"
      id="detailsModal"
      size="lg"
      hide-header
      body-class="pl-0 pr-0"
      ok-disabled
    >
      <Brand />
      <UserDetails :userItem="userItem" />
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="danger"
            size="sm"
            class="float-right"
            @click="$bvModal.hide('detailsModal')"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      ref="company-modal"
      id="company-modal"
      size="lg"
      body-class="pl-0 pr-0"
      hide-footer
      ok-disabled
    >
      <b-container>
        <CompanyForm
          v-if="userItem"
          :companyItem="userItem"
          @closeModal="closeModal"
        />
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="danger"
            size="sm"
            class="float-right"
            @click="$bvModal.hide('company-modal')"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";

import TableBase from "@/modules/shared/table.vue";
import UserDetails from "@/modules/buyer/Details.vue";
import Brand from "@/modules/shared/brand.vue";
import { GET_USERS_COMPANY } from "./actions";
import CompanyForm from "@/modules/buyer/registrationForm/CompanyForm";
import { generalMixin } from "@/modules/mixin.js";
export default {
  components: { TableBase, UserDetails, Brand, CompanyForm },
  mixins: [generalMixin],
  computed: {
    ...mapState({
      users: (state) => state.adminStore.users.users.data,
    }),
  },

  data() {
    return {
      fields: [
        { key: "createdAt", label: "Created at" },

        { key: "user.firstName", label: "First name" },
        { key: "user.lastName", label: "Last name" },
        { key: "user.email", label: "Email" },
        {
          key: "user.userDetails.officePhoneNumber",
          label: "Office phone number",
        },

        { key: "user.userDetails.mobileNumber", label: "Phone number" },
        {
          key: "actions",
          label: "Actions",
          note: false,
          details: true,
          validation: false,
          update: true,
        },
      ],
      options: {
        assign: true,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      userItem: {},
    };
  },
  methods: {
    async getData(filter = "") {
      this.isBusy = true;

      const payload = {
        search: filter,
        type: "active",
      };
      this.$store
        .dispatch(GET_USERS_COMPANY, payload)
        .then(() => {
          this.isBusy = false;
        })
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
      this.isBusy = false;
    },
    editItem(item) {
      this.userItem = item;
      this.$bvModal.show("detailsModal");
    },
    updateItem(item) {
      this.userItem = item;
      this.$bvModal.show("company-modal");
    },

    changePage(page, filter = "") {
      this.isBusy = true;
      const payload = {
        search: filter,
        type: "active",
        page: page,
      };
      this.$store
        .dispatch(GET_USERS_COMPANY, payload)
        .then((response) => {
          this.data = response;
          this.isBusy = false;
        })
        .catch(() => {
          this.$root.$bvToast.toast("Warning", {
            title: "Warning",
            variant: "warning",
          });
        });
      this.isBusy = false;
    },
    closeModal() {
      this.$bvModal.hide("company-modal");
      this.userItem = {};
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
