<template>
  <div class="my-auto mt-5">
    <img
      v-if="showPixel"
      height="1"
      width="1"
      style="border-style: none"
      alt=""
      src="https://insight.adsrvr.org/track/pxl/?adv=pzdunu8&ct=0:xtknu9t&fmt=3"
    />

    <!-- Note :
   - You can modify the font style and form style to suit your website. 
   - Code lines with comments Do not remove this code are required for the form to work properly, make sure that you do not remove these lines of code. 
   - The Mandatory check script can modified as to suit your business needs. 
   - It is important that you test the modified form before going live.
   - Do not remove captcha and other form elements that are necessary for the form to work.  -->
    <div
      id="crmWebToEntityForm"
      class="zcwf_lblLeft crmWebToEntityForm"
      style="background-color: white; color: black; max-width: 600px"
    >
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <META HTTP-EQUIV="content-type" CONTENT="text/html;charset=UTF-8" />
      <form
        action="https://crm.zoho.com/crm/WebToLeadForm"
        name="WebToLeads5763641000000475003"
        method="POST"
        onSubmit='javascript:document.charset="UTF-8"; return checkMandatory5763641000000475003()'
        accept-charset="UTF-8"
      >
        <input
          type="text"
          style="display: none"
          name="xnQsjsdp"
          value="298bbe1fe6fdad944bbd31784d87363dd31d170f3972b89ecfef5edc9cd45b65"
        />
        <input type="hidden" name="zc_gad" id="zc_gad" value="" />
        <input
          type="text"
          style="display: none"
          name="xmIwtLD"
          value="327bea59ce669c6ed883e8f6fb277e8d4e69b7311fd135372c8d54944b301d69"
        />
        <input
          type="text"
          style="display: none"
          name="actionType"
          value="TGVhZHM="
        />
        <input
          type="text"
          style="display: none"
          name="returnURL"
          value="https://www.canamcar.com/#/"
        />
        <!-- Do not remove this code. -->

        <div
          class="zcwf_title"
          style="max-width: 600px; color: black; font-family: Arial"
        >
          CANAMCAR SITE
        </div>
        <div class="zcwf_row">
          <div class="zcwf_col_lab" style="font-size: 12px; font-family: Arial">
            <label for="Company"
              >Company<span style="color: red">*</span></label
            >
          </div>
          <div class="zcwf_col_fld">
            <input type="text" id="Company" name="Company" maxlength="200" />
            <div class="zcwf_col_help"></div>
          </div>
        </div>
        <div class="zcwf_row">
          <div class="zcwf_col_lab" style="font-size: 12px; font-family: Arial">
            <label for="First_Name">First Name</label>
          </div>
          <div class="zcwf_col_fld">
            <input
              type="text"
              id="First_Name"
              name="First Name"
              maxlength="40"
            />
            <div class="zcwf_col_help"></div>
          </div>
        </div>
        <div class="zcwf_row">
          <div class="zcwf_col_lab" style="font-size: 12px; font-family: Arial">
            <label for="Last_Name"
              >Last Name<span style="color: red">*</span></label
            >
          </div>
          <div class="zcwf_col_fld">
            <input type="text" id="Last_Name" name="Last Name" maxlength="80" />
            <div class="zcwf_col_help"></div>
          </div>
        </div>
        <div class="zcwf_row">
          <div class="zcwf_col_lab" style="font-size: 12px; font-family: Arial">
            <label for="Phone">Phone</label>
          </div>
          <div class="zcwf_col_fld">
            <input type="text" id="Phone" name="Phone" maxlength="30" />
            <div class="zcwf_col_help"></div>
          </div>
        </div>
        <div class="zcwf_row">
          <div class="zcwf_col_lab" style="font-size: 12px; font-family: Arial">
            <label for="Email">Email</label>
          </div>
          <div class="zcwf_col_fld">
            <input
              type="text"
              ftype="email"
              id="Email"
              name="Email"
              maxlength="100"
            />
            <div class="zcwf_col_help"></div>
          </div>
        </div>
        <div class="zcwf_row">
          <div class="zcwf_col_lab" style="font-size: 12px; font-family: Arial">
            <label for="Description">Description</label>
          </div>
          <div class="zcwf_col_fld">
            <textarea id="Description" name="Description"></textarea>
            <div class="zcwf_col_help"></div>
          </div>
        </div>
        <div class="zcwf_row">
          <div class="zcwf_col_lab" style="font-size: 12px; font-family: Arial">
            Enter the Captcha
          </div>
          <div class="zcwf_col_fld">
            <input type="text" maxlength="10" name="enterdigest" />
          </div>
        </div>
        <!-- Do not remove this code. -->
        <div class="zcwf_row">
          <div class="zcwf_col_lab"></div>
          <div class="zcwf_col_fld">
            <img
              id="imgid5763641000000475003"
              src="https://crm.zoho.com/crm/CaptchaServlet?formId=327bea59ce669c6ed883e8f6fb277e8d4e69b7311fd135372c8d54944b301d69&grpid=298bbe1fe6fdad944bbd31784d87363dd31d170f3972b89ecfef5edc9cd45b65"
            />
            <a href="javascript:;" @click="reloadImg5763641000000475003()"
              >Reload</a
            >
          </div>
          <div class=""></div>
        </div>
        <div class="zcwf_row">
          <div class="zcwf_col_lab"></div>
          <div class="zcwf_col_fld">
            <input
              type="submit"
              id="formsubmit"
              class="formsubmit zcwf_button"
              value="Submit"
              title="Submit"
            /><input
              type="reset"
              class="zcwf_button"
              name="reset"
              value="Reset"
              title="Reset"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { POST_CONTACT_FORM } from "../actions";
import { required, minLength, email } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      form: {
        fullName: null,
        email: null,
        phoneNumber: null,
        message: null,
      },
      options: {
        type: "tel",
        validCharactersOnly: true,
        maxlength: 14,
        placeholder: "Contact Phone Number",
      },
      telDdOptions: {
        showDialCodeInList: true,
        showFlags: true,
        showDialCodeInSelection: false,
      },
      showPixel: false,
    };
  },
  computed: {
    contactFormStatus() {
      return this.$store.state.home.contactForm.status;
    },
  },
  validations: {
    form: {
      fullName: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
      message: {
        required,
      },
      phoneNumber: {
        required,
        minLength: minLength(10),
      },
    },
  },

  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form.fullName = null;
      this.form.email = null;
      this.form.phoneNumber = null;
      this.form.message = null;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    sendContact() {
      this.showPixel = true;
      setTimeout(() => {
        this.showPixel = false;
      }, 1000);
      this.$store
        .dispatch(POST_CONTACT_FORM, this.form)
        .then(() => {
          this.resetForm();
          this.$root.$bvToast.toast("Contact information sent", {
            title: "Success",
            variant: "success",
          });
        })
        .catch(() => {
          this.$root.$bvToast.toast("Contact information not submitted", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    reloadImg5763641000000475003() {
      var captcha = document.getElementById("imgid5763641000000475003");
      if (captcha.src.indexOf("&d") !== -1) {
        captcha.src =
          captcha.src.substring(0, captcha.src.indexOf("&d")) +
          "&d" +
          new Date().getTime();
      } else {
        captcha.src = captcha.src + "&d" + new Date().getTime();
      }
    },
    validateEmail5763641000000475003() {
      var form = document.forms["WebToLeads5763641000000475003"];
      var emailFld = form.querySelectorAll("[ftype=email]");
      var i;
      for (i = 0; i < emailFld.length; i++) {
        var emailVal = emailFld[i].value;
        if (emailVal.replace(/^\s+|\s+$/g, "").length != 0) {
          var atpos = emailVal.indexOf("@");
          var dotpos = emailVal.lastIndexOf(".");
          if (
            atpos < 1 ||
            dotpos < atpos + 2 ||
            dotpos + 2 >= emailVal.length
          ) {
            alert("Please enter a valid email address. ");
            emailFld[i].focus();
            return false;
          }
        }
      }
      return true;
    },
    checkMandatory5763641000000475003() {
      var mndFileds = new Array("Company", "Last Name");
      var fldLangVal = new Array("Company", "Last\x20Name");
      for (var i = 0; i < mndFileds.length; i++) {
        var fieldObj =
          document.forms["WebToLeads5763641000000475003"][mndFileds[i]];
        if (fieldObj) {
          if (fieldObj.value.replace(/^\s+|\s+$/g, "").length == 0) {
            if (fieldObj.type == "file") {
              alert("Please select a file to upload.");
              fieldObj.focus();
              return false;
            }
            alert(fldLangVal[i] + " cannot be empty.");
            fieldObj.focus();
            return false;
          } else if (fieldObj.nodeName == "SELECT") {
            if (fieldObj.options[fieldObj.selectedIndex].value == "-None-") {
              alert(fldLangVal[i] + " cannot be none.");
              fieldObj.focus();
              return false;
            }
          } else if (fieldObj.type == "checkbox") {
            if (fieldObj.checked == false) {
              alert("Please accept  " + fldLangVal[i]);
              fieldObj.focus();
              return false;
            }
          }
          try {
            if (fieldObj.name == "Last Name") {
              fieldObj.name = fieldObj.value;
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
      if (!this.validateEmail5763641000000475003()) {
        return false;
      }
      document
        .querySelector(".crmWebToEntityForm .formsubmit")
        .setAttribute("disabled", true);
    },

    tooltipShow5763641000000475003(el) {
      var tooltip = el.nextElementSibling;
      var tooltipDisplay = tooltip.style.display;
      if (tooltipDisplay == "none") {
        var allTooltip = document.getElementsByClassName("zcwf_tooltip_over");
        for (var i = 0; i < allTooltip.length; i++) {
          allTooltip[i].style.display = "none";
        }
        tooltip.style.display = "block";
      } else {
        tooltip.style.display = "none";
      }
    },
  },
};
</script>

<style>
.no-resize {
  resize: none;
}
</style>
