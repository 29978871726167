<template>
  <div>
    <b-navbar variant="blue" class="v-25 text-blue mb-2">a </b-navbar>

    <div class="container-fluid">
      <b-row>
        <b-col>
          <h4 class="ml-3">Agents</h4>
        </b-col>
      </b-row>
      <div class="pt-4">
        <TableBase
          :items="users.data"
          :fields="fields"
          :options="options"
          :busy="users.status === 'fetching' ? true : false"
        >
          <template v-slot:rightSide>
            <div>
              <b-button
                variant="outline-purple-blue"
                @click="showModal()"
                class="align-bottom"
              >
                <b-icon icon="person-plus-fill" class="h4 m-1 align-bottom" />
                <span class=""> </span>
              </b-button>
            </div>
          </template>
        </TableBase>
      </div>

      <b-modal
        ref="detailsModal"
        id="detailsModal"
        size="lg"
        hide-footer
        body-class="pl-0 pr-0"
        ok-disabled
        @hidden="userItem = {}"
      >
        <Brand />
        <UserForm
          :userItem="userItem"
          :refreshData="getData"
          :hideModal="hideModal"
        />
      </b-modal>
    </div>
  </div>
</template>
<script>
import TableBase from "@/modules/shared/table.vue";
import UserForm from "./Form";
import Brand from "@/modules/shared/brand.vue";
import {
  DELETE_USER_BUYER,
  PUT_USER_BUYER,
} from "@/modules/buyer/users/actions";
import { GET_USERS } from "./actions";
import { mapState } from "vuex";

import { generalMixin } from "@/modules/mixin.js";
export default {
  components: { TableBase, UserForm, Brand },
  mixins: [generalMixin],
  computed: {
    ...mapState({
      users: (state) => state.adminStore.agents.users,
    }),
  },
  data() {
    return {
      fields: [
        { key: "nickname", label: "Nickname" },

        { key: "firstName", label: "First name" },
        { key: "lastName", label: "Last name" },
        { key: "email", label: "Email" },
        { key: "userDetails.mobileNumber", label: "Phone number" },
        {
          key: "actions",
          label: "Actions",
          note: false,
          details: true,
          validation: false,
          delete: true,
        },
      ],
      options: {
        assign: false,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      userItem: {},
    };
  },
  methods: {
    async getData(filter = "") {
      const payload = {
        roleName: "ROLE_AGENT",

        search: filter,
      };
      this.$store
        .dispatch(GET_USERS, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    async deleteItem(item) {
      const confirm = await this.showConfirmDeleteBox();
      if (!confirm) return;
      const payload = {
        id: item.id,
        status: 0,
      };
      this.$store
        .dispatch(DELETE_USER_BUYER, payload)
        .then(() => {
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    async activateItem(item) {
      const payload = {
        id: item.id,
        status: 1,
      };
      this.$store
        .dispatch(PUT_USER_BUYER, payload)
        .then(() => {
          this.makeToast("Success", "User updated successfully", "success");
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast(this.user.error.response.data.message, {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    showModal() {
      this.$bvModal.show("detailsModal");
    },
    editItem(item) {
      this.userItem = item;
      this.$bvModal.show("detailsModal");
    },
    changePage(page, filter = "") {
      const payload = {
        roleName: "ROLE_AGENT",
        search: filter,
        page: page,
      };
      this.$store
        .dispatch(GET_USERS, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },

    hideModal() {
      this.$bvModal.hide("detailsModal");
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
