<template>
  <div>
    <b-navbar variant="blue" class="v-25 text-blue">asg </b-navbar>

    <div
      v-if="notifications.data && !notifications.data.length"
      class="text-center mt-3"
    >
      No notifications to show
    </div>
    <div class="container-fluid" v-else>
      <b-row
        class="justify-content-center border-purple-blue border-bottom"
        v-for="(notification, index) in notifications.data"
        :key="index"
      >
        <b-col sm="12" md="1" lg="1" xl="1"> </b-col>
        <b-col sm="12" md="10" lg="10" xl="10" align-self="center">
          <NotificationCard :notification="notification" class="" />
        </b-col>
        <b-col sm="12" md="1" lg="1" xl="1"> </b-col>

        <hr />
      </b-row>
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="d-flex justify-content-end mt-2"
        >
          <b-pagination
            v-if="notifications.data"
            :value="notifications.currentPage"
            :total-rows="notifications.total"
            :per-page="notifications.perPage"
            @change="changePage($event)"
            class="mb-2"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import NotificationCard from "./NotificationCard";
import { mapState } from "vuex";
import {
  SET_NOTIFICATIONS_FILTERS,
  GET_NOTIFICATIONS,
  PUT_NOTIFICATIONS,
} from "../../admin/notifications/actions";

export default {
  data() {
    return {
      sortedByMostRecent: false,
    };
  },
  components: { NotificationCard },
  computed: {
    ...mapState({
      notifications: (state) =>
        state.adminStore.notifications.notifications.data,
    }),
  },
  methods: {
    async getNotifications() {
      await this.$store
        .dispatch(GET_NOTIFICATIONS, {})
        .then((response) => {
          let notificationsToUpdate = [];
          response.data.forEach((value) => {
            if (value.sent !== 2) notificationsToUpdate.push(value.id);
          });
          if (notificationsToUpdate.length > 0)
            this.updateNotifications(notificationsToUpdate);
        })
        .catch(() => {});
    },
    async changePage(page) {
      this.$store.commit(SET_NOTIFICATIONS_FILTERS, { fields: { page } });
      this.getNotifications();
    },
    sortByMostRecent() {
      this.sortedByMostRecent = true;
      this.$store.commit(SET_NOTIFICATIONS_FILTERS, {
        fields: { new: true },
      });
      this.getNotifications();
    },
    updateNotifications(notifications) {
      this.$store
        .dispatch(PUT_NOTIFICATIONS, { notifications })
        .then(() => {})
        .catch(() => {});
    },
  },
  mounted() {
    this.$store.commit(SET_NOTIFICATIONS_FILTERS, { reset: true });
    this.getNotifications();
  },
  destroyed() {
    this.$store.commit(SET_NOTIFICATIONS_FILTERS, {
      reset: true,
    });
  },
};
</script>

<style scoped>
.bl-2 {
  border-left: 10px solid #35a4dd !important;
}
</style>
