import store from "@/store";
import { SET_VEHICLES_FILTERS } from "./actions";
const listVehiclesMixin = {
  data() {
    return {
      client: null,
      agent: null,
      agentId: null,
      location: null,
      search: null,
      make: null,
      model: null,
      trim: null,
      releaseDate1: null,
      releaseDate2: null,
      year1: null,
      year2: null,
      yearRange: [0, 0],
      mile1: null,
      mile2: null,
      transmissionType: null,
      price1: null,
      price2: null,
      mileageRange: [0, 200000],
      maxMileage: 200000,
      priceRange: [0, 200000],
      maxPrice: 200000,
      new: null,
      status: "READYTOSELL",
      saleDateFrom: null,
      saleDateTo: null,
      published: 1,
      noPublished: 1,
      fullInventory: 0,
    };
  },
  computed: {
    filterAction() {
      return store.state.adminStore.vehicles.listStore.filterAction.action;
    },
    allVehicles() {
      return store.state.adminStore.vehicles.listStore.vehicles.data;
    },
    publicVehicles() {
      return store.state.home.vehicles.data;
    },
    activeOffers() {
      return store.state.adminStore.vehicles.offersStore.activeOffers.data;
    },
    expiredOffers() {
      return store.state.adminStore.vehicles.offersStore.expiredOffers.data;
    },
    purchases() {
      return store.state.adminStore.vehicles.offersStore.purchases.data;
    },
    vehiclesWithOffers() {
      return store.state.adminStore.vehicles.offersStore.vehiclesWithOffers
        .data;
    },
  },
  methods: {
    getActiveOffers() {
      let action = "GET_ACTIVE_OFFERS";
      const payload = {
        action,
        status: 1,
      };
      this.$store.dispatch("GET_OFFERS_BY_USER", payload);
    },
    getExpiredOffers() {
      let action = "GET_EXPIRED_OFFERS";
      const payload = {
        action,
        status: 0,
      };
      this.$store.dispatch("GET_OFFERS_BY_USER", payload);
    },
    getPurchases() {
      this.$store.dispatch("GET_PURCHASES");
    },
    getPublicVehicles() {
      this.$store.dispatch("GET_PUBLIC_VEHICLES", true);
    },
    clearElementsInSearch() {
      this.client = null;
      this.agent = null;
      this.agentId = null;
      this.location = null;
      this.search = null;
      this.make = null;
      this.model = null;
      this.trim = null;
      this.releaseDate1 = null;
      this.releaseDate2 = null;
      this.year1 = null;
      this.year2 = null;
      this.yearRange = [this.minYear, this.maxYear];
      this.mile1 = null;
      this.mile2 = null;
      this.transmissionType = null;
      this.price1 = null;
      this.price2 = null;
      this.mileageRange = [0, 200000];
      this.maxMileage = 200000;
      this.priceRange = [0, 200000];
      this.maxPrice = 200000;
      this.new = null;
      this.status = null;
      this.published = 1;
      this.noPublished = 1
      this.fullInventory = 0;
    },
    resetFilters(getData) {
      this.clearElementsInSearch();
      this.$store.commit(SET_VEHICLES_FILTERS, { reset: true });

      if (getData) {
        switch (this.filterAction) {
          case "GET_VEHICLES":
            this.getVehicles();
            break;
          case "PUBLIC_GET_VEHICLES":
            this.getPublicVehicles();
            break;
          case "GET_ACTIVE_OFFERS":
            this.getActiveOffers();
            break;
          case "GET_EXPIRED_OFFERS":
            this.getExpiredOffers();
            break;
          case "GET_PURCHASES":
            this.getPurchases();
            break;
          case "GET_VEHICLES_WITH_OFFERS":
            this.$store.dispatch(this.filterAction, true);
            break;
        }
      }
    },
    searchVehiclesByTabMenu() {
      switch (this.filterAction) {
        case "GET_VEHICLES":
          this.getVehicles();
          break;
        case "PUBLIC_GET_VEHICLES":
          this.getPublicVehicles();
          break;
        case "GET_ACTIVE_OFFERS":
          this.getActiveOffers();
          break;
        case "GET_EXPIRED_OFFERS":
          this.getExpiredOffers();
          break;
        case "GET_PURCHASES":
          this.getPurchases();
          break;
        case "GET_VEHICLES_WITH_OFFERS":
          this.$store.dispatch(this.filterAction, true);
          break;
      }
    },
  },
};

export { listVehiclesMixin };
