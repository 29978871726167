<template>
  <div>
    <b-button
      v-if="userIsAdmin || userIsAgent"
      :variant="notifications > 0 ? 'pink' : 'purple-blue'"
      class="offer-button"
      @click="showOffers()"
      size="lg"
    >
      <strong class="">
        OFFERS
        <span
          v-if="
            typeof notifications === 'number' &&
            notifications > 0 &&
            (userIsAdmin || userIsAgent)
          "
        >
          {{ notifications }}
        </span>
      </strong>
    </b-button>
  </div>
</template>

<script>
import { generalMixin } from "@/modules/mixin.js";
import {
  SET_NOTIFICATIONS_FILTERS,
  GET_NOTIFICATIONS,
} from "@/modules/admin/notifications/actions";
import {
  GET_VEHICLES_WITH_OFFERS,
  SHOW_OFFER_SIDEBAR,
  SET_OFFER_SIDEBAR,
} from "@/modules/admin/vehicles/offers/actions";
import { mapState } from "vuex";

export default {
  mixins: [generalMixin],
  computed: {
    ...mapState({
      notifications: (state) =>
        state.adminStore.notifications.notifications.data,
      profile: (state) => state.auth.loginStore.profile.data,
    }),
  },
  data() {
    return {
      notificationsInterval: null,
    };
  },
  methods: {
    getNotifications() {
      if (this.user == null) {
        return;
      } else {
        this.$store.commit(SET_NOTIFICATIONS_FILTERS, {
          fields: { counter: true },
        });
        this.$store
          .dispatch(GET_NOTIFICATIONS)
          .then(() => {})
          .catch(() => {
            this.toastMessage(
              "The notifications could not be obtained",
              "Warning",
              "warning"
            );
          });
      }
    },
    notificationCounter() {
      this.notificationsInterval = setInterval(this.getNotifications, 180000);
    },

    showOffers() {
      this.showOffersFullScreen();
    },
    showOffersFullScreen() {
      this.user.roles.forEach((value) => {
        switch (value.name) {
          case "ROLE_ADMIN":
            this.$router.push({ name: "admin.vehicles.offers" }).catch(() => {
              this.getOffers();
            });
            break;
          case "ROLE_AGENT":
            this.$router.push({ name: "agent.vehicles.offers" }).catch(() => {
              this.getOffers();
            });
            break;
        }
      });
    },
    getOffers() {
      this.$store.commit(SHOW_OFFER_SIDEBAR, false);
      this.$store.commit(SET_OFFER_SIDEBAR, null);
      this.$store.dispatch(GET_VEHICLES_WITH_OFFERS, true);
    },
  },
  watch: {
    user: function (data) {
      if (data) {
        this.getNotifications();
        this.notificationCounter();
      } else {
        if (this.notificationsInterval)
          clearInterval(this.notificationsInterval);
      }
    },
  },
};
</script>

<style scoped>
.offer-button {
  width: 8.2rem !important;
  font-size: 1.1rem !important;
}

@media (min-width: 576px) {
  .offer-button {
    width: 10rem !important;
    font-size: 1.1rem !important;
  }
}
</style>
