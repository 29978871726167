import Base from "./Base.vue";
import List from "./vehicles/list/List.vue";
import RegistryVehicle from "./vehicles/registry/SearchVehicleByVin.vue";
import GeneralInformation from "./vehicles/registry/GeneralInformation";
import SuccessCarUploaded from "./vehicles/registry/SuccessCarUploaded";
import NavUsers from "./users/Nav.vue";
import PendingList from "./users/PendingList.vue";
import ActiveList from "./users/ActiveList.vue";
import RejectedList from "./users/RejectedList.vue";
import NotificationsList from "./notifications/List.vue";
import Agents from "./agent/List.vue";
import AssignCompanyTable from "./assignCompany/AssignCompanyTable.vue";
import Download from "../../pages/Download.vue"
import {
  ifRoleAuthenticated,
  ifAuthenticated,
} from "@/router/navigationGuards";
import OffersFullScreen from "./vehicles/offers/OffersFullScreen";
import DisableNotifications from "./users/DisableNotifications";

const routes = [
  {
    path: "/admin",
    name: "admin",
    component: Base,
    beforeEnter: (to, from, next) => {
      ifRoleAuthenticated(to, from, next, ["ROLE_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_REVIEWER"]);
    },
    children: [
      {
        path: "vehicles",
        name: "admin.vehicles",
        component: Base,
        children: [
          {
            path: "list",
            name: "admin.vehicles.list",
            component: List,
          },
          {
            path: "registry",
            name: "admin.vehicles.registry",
            component: RegistryVehicle,
          },
          {
            path: "registry/:vin",
            name: "admin.vehicles.registry.general",
            component: GeneralInformation,
          },
          {
            path: "registry/:vin/uploaded",
            name: "admin.vehicles.registry.uploaded",
            component: SuccessCarUploaded,
          },
          {
            path: "offers",
            name: "admin.vehicles.offers",
            component: OffersFullScreen,
          },
        ],
      },
      {
        path: "users",
        name: "admin.users",
        component: NavUsers,
        children: [
          {
            path: "registry",
            name: "admin.users.registry",
            component: PendingList,
          },
          {
            path: "validate",
            name: "admin.users.validate",
            component: ActiveList,
          },
          {
            path: "rejected",
            name: "admin.users.rejected",
            component: RejectedList,
          },
        ],
      },
      {
        path: "notifications",
        name: "admin.notifications",
        component: NotificationsList,
      },
      {
        path: "agent",
        name: "admin.agent",
        component: Agents,
      },
      {
        path: "assign-company",
        name: "admin.assign-company",
        component: AssignCompanyTable,
        meta: { layout: "base" },
      },
    ],
  },
  {
    path: "/disable/notifications",
    name: "disable-notifications",
    component: DisableNotifications,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
  },
  {
    path: "/disable/notifications/:token",
    name: "disable-notifications-public",
    component: DisableNotifications,
  },
  {
    path: "/download",
    name: "download",
    component: Download,
  }
];

export default routes;
