var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"mt-0",attrs:{"fluid":""}},[_c('b-table',{attrs:{"responsive":"","thead-class":"text-center bg-light-grey ","head-variant":"light","bordered":"","small":"","show-empty":"","outlined":"","items":_vm.vehicles,"fields":_vm.fields,"stacked":"sm"},scopedSlots:_vm._u([{key:"cell(image)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mx-auto"},[_c('vehicle-card-gallery',{staticStyle:{"max-width":"15rem !important"},attrs:{"images":_vm.formatImages(_vm.getImagesOfVehicle(item)),"showThumbnail":false,"typeList":'list'}}),_c('card-title',{staticStyle:{"max-width":"15rem !important"},attrs:{"vehicle":item}})],1)]}},{key:"cell(vinNumber)",fn:function(ref){
var item = ref.item;
return [(item.vinNumber.vinNumber)?_c('router-link',{attrs:{"to":_vm.getVehiclePageUrl(item)}},[_c('div',[_c('i',[_vm._v(" "+_vm._s(item.vinNumber.vinNumber)+" ")])])]):_vm._e()]}},{key:"cell(year)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.vinNumber.year)+" ")]}},{key:"cell(make)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.vinNumber.make)+" ")]}},{key:"cell(model)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.vinNumber.model)+" ")]}},{key:"cell(miles)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.vehicleInformation.miles))+" Mi ")]}},{key:"cell(cr)",fn:function(ref){
var item = ref.item;
return [(item.vehicleHasConditionalReports.length > 0)?_c('CRButton',{staticClass:"mx-auto",attrs:{"vehicle":item}}):_vm._e()]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [(item.vehicleCostDetail && item.vehicleCostDetail.price)?_c('div',{staticClass:"mb-2 pt-2 text-center"},[_c('strong',[_vm._v(" "+_vm._s(_vm._f("currency")(item.vehicleCostDetail.price))+" ")])]):_vm._e(),(_vm.vehicleAcceptOffer(item) || item.status === 'Sold')?_c('div',{staticClass:"m"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/TOOLATE.png"),"alt":"sold-image"}})]):_c('div',[(_vm.viewOffers)?_c('b-row',{},[_c('b-col',{staticClass:"mb-1"},[_c('b-button',{staticClass:"shadow",attrs:{"variant":"purple-blue","block":""},on:{"click":function($event){return _vm.showVehicleOffers(item)}}},[_vm._v(" View Offers ")])],1)],1):(!_vm.isHome && _vm.user)?_c('b-row',{},[_c('b-col',[(
                item.status !== 'Inactive' &&
                (_vm.userIsAdmin || (_vm.userIsAgent && item.usersId === _vm.user.id))
              )?_c('b-button',{attrs:{"variant":"purple-blue","block":""},on:{"click":function($event){return _vm.editVehicle(item.vinNumber.vinNumber)}}},[_c('b-icon',{attrs:{"icon":"pencil"}})],1):_vm._e()],1)],1):_vm._e(),(item.vehicleFlags.wo != 0 && _vm.userIsAdmin)?_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"img-responsive mt-1",attrs:{"height":"25","src":require("@/assets/icon/work-order-icon.png"),"title":"This vehicle has had a work order"}}):_vm._e(),(
            !_vm.isHome &&
            !_vm.isActiveOffer &&
            !_vm.isExpiredOffer &&
            (_vm.userHasOffer || item.lastOffer) &&
            _vm.userHasCompany
          )?_c('b-row',{},[_c('b-col',[_c('b-button',{attrs:{"variant":"purple-blue","block":"","size":"sm"},on:{"click":function($event){return _vm.changeOfferTab(item.id)}}},[_c('h5',{},[_vm._v(" Current Offer: "),(item.lastOffer)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.lastOffer.offerPrice))+" ")]):(_vm.userHasOffer)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.currentOffer))+" ")]):_vm._e()])])],1)],1):_vm._e()],1),(
          !_vm.isHome &&
          !_vm.userHasOffer &&
          !item.lastOffer &&
          !_vm.isExpiredOffer &&
          !_vm.isActiveOffer &&
          !_vm.userIsAdmin
        )?_c('MakeAnOffer',{attrs:{"vehicleId":item.id,"price":item.vehicleCostDetail.price,"userPending":!_vm.userHasCompany,"stacked":true,"vehicle":item},on:{"setUserHasOffer":function($event){return _vm.setUserHasOffer($event)},"refresh":function($event){return _vm.refreshVehicles()}}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }