import Home from "./Home";
import { ifAuthenticated } from "@/router/navigationGuards";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next, "ROLE_ADMIN");
    },
  },
];
export default routes;
