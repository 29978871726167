<template>
  <div
    class="py-2 text-center text-white"
    :class="
      getDaysUntilReleaseDate(vehicle.releaseDate) > 0
        ? 'bg-purple-blue'
        : 'bg-success'
    "
  >
    <span v-if="getDaysUntilReleaseDate(vehicle.releaseDate) > 0">
      Available in
      <strong>
        {{ getDaysUntilReleaseDate(vehicle.releaseDate) }} day{{
          getDaysUntilReleaseDate(vehicle.releaseDate) > 1 ? "s" : ""
        }}
      </strong>
    </span>
    <span v-else> Available <strong>Now</strong> </span>
  </div>
</template>

<script>
export default {
  name: "CardTitle",
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getDaysUntilReleaseDate(releaseDate) {
      const today = new Date();
      const release = new Date(releaseDate);
      const diffTime = release.getTime() - today.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
  },
};
</script>

<style>
</style>