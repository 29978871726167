<template>
  <div>
    <b-row class="m-0 p-0">
      <b-col xl="8" lg="8" md="8" sm="0" class="my-auto">
        <video width="100%" height="100%" controls>
          <source src="@/assets/video/camioneta.mp4#t=2" type="video/mp4" />
        </video>
      </b-col>

      <b-col xl="4" lg="4" md="4" sm="12" class="">
        <b-card no-body img-top v-if="!vehiclesStatus == 'success'">
          <b-skeleton-img card-img="top" aspect="3:1"></b-skeleton-img>
          <b-card-body>
            <b-skeleton-table
              :rows="5"
              :columns="1"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
          </b-card-body>
        </b-card>

        <VueSlickCarousel
          v-if="vehiclesStatus == 'success'"
          :arrows="true"
          :dots="true"
          v-bind="settings"
          dotsClass="slick-dots"
        >
          <VehicleCard
            v-for="vehicle in vehicles.data"
            :key="vehicle.id"
            :vehicle="vehicle"
          />
        </VueSlickCarousel>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { GET_PUBLIC_VEHICLES } from "../actions";
import { SET_VEHICLES_FILTERS } from "@/modules/admin/vehicles/list/actions";
import VehicleCard from "@/modules/admin/vehicles/list/VehicleCard";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  data() {
    return {
      params: {},
      settings: {
        arrows: true,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 800,
        autoplaySpeed: 3500,
        cssEase: "linear",
      },
    };
  },
  components: { VehicleCard, VueSlickCarousel },
  computed: {
    ...mapState({
      vehicles: (state) => state.home.vehicles.data,
      currentPage: (state) => state.home.vehicles.data.currentPage,
      total: (state) => state.home.vehicles.data.total,
      perPage: (state) => state.home.vehicles.data.perPage,
      vehiclesStatus: (state) => state.home.vehicles.status,
    }),
  },
  methods: {
    getVehicles() {
      this.$store.commit(SET_VEHICLES_FILTERS, { reset: true });
      this.$store
        .dispatch(GET_PUBLIC_VEHICLES, this.params)
        .then(() => {})
        .catch(() => {});
    },
  },

  mounted() {
    this.getVehicles();
  },
};
</script>

<style scoped lang="scss">
.slick-dots li button {
  font-size: 15px !important;
  background-color: red !important;
}

.slick-active {
  font-size: 15px;
}
</style>
