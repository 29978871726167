<template>
  <div class="container">
    <b-form class="m-4" @submit.stop.prevent="form.id ? update() : save()">
      <h3 class="text-purple-blue">
        <b-icon icon="person-plus-fill" class="h4 m-1 align-bottom"></b-icon>
      </h3>
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
          <!-- First name -->
          <b-form-group label-size="sm">
            <template slot="label">
              First name <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="sm"
              id="firstName-input"
              name="firstName-input"
              @change="setValue('name', $event)"
              :value="form.name"
              v-model.trim="$v.form.name.$model"
              :state="validateState('name')"
              aria-describedby="input-firstName-live-feedback"
              maxlength="100"
            >
            </b-form-input>
            <b-form-invalid-feedback id="input-firstName-live-feedback">
              This is a required field and must at least 2 characters.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <!-- Last name -->
          <b-form-group label-size="sm">
            <template slot="label"
              >Last name <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="sm"
              id="lastName-input"
              name="lastName-input"
              @change="setValue('lastName', $event)"
              :value="form.lastName"
              v-model.trim="$v.form.lastName.$model"
              :state="validateState('lastName')"
              aria-describedby="input-lastName-live-feedback"
              maxlength="100"
            >
            </b-form-input>
            <b-form-invalid-feedback id="input-lastName-live-feedback">
              This is a required field and must at least 2 characters.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="6" lg="6" xl="6">
          <!-- Email -->
          <b-form-group label-size="sm">
            <template slot="label">
              Email <span class="text-danger"> *</span>
            </template>
            <b-form-input
              size="sm"
              id="email-input"
              name="email-input"
              :formatter="lowerCaseFormatter"
              @change="setValue('email', $event)"
              :value="form.email"
              v-model.trim="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="input-email-live-feedback"
              maxlength="255"
            />
            <b-form-invalid-feedback id="input-email-live-feedback">
              This is a required field and must be a valid email.
            </b-form-invalid-feedback>
            <SendPasswordEmail
              v-if="!$v.form.email.$invalid && this.userItem.id"
              :email="form.email"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="6">
          <!-- Office phone number -->
          <b-form-group label-size="sm">
            <template slot="label">
              Office phone number <span class="text-danger">*</span>
            </template>
            <b-input-group>
              <vue-tel-input
                defaultCountry="us"
                :inputOptions="options"
                :dropdownOptions="telDdOptions"
                :autoFormat="true"
                :validCharactersOnly="true"
                mode="international"
                styleClasses="z-index-2"
                @change="setValue('officePhoneNumber', $event)"
                v-model.trim="$v.form.officePhoneNumber.$model"
                :value="form.officePhoneNumber"
              />

              <b-form-input
                size="sm"
                type="number"
                maxlength="6"
                onkeydown="javascript: return event.keyCode == 69 ? false : true"
                class="append-input"
                id="extension-input"
                placeholder="ext"
                name="extension-input"
                @change="setValue('extension', $event)"
                :value="form.extension"
                v-model.trim="$v.form.extension.$model"
                :state="validateState('extension')"
              >
              </b-form-input>
              <b-form-invalid-feedback id="input-office-number-live-feedback">
                This field must at least contain 10 digits.
              </b-form-invalid-feedback>
              <b-form-invalid-feedback id="input-extension-live-feedback">
                This is a numeric field
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="6">
          <!-- Mobile phone -->
          <b-form-group label-size="sm" label="Mobile phone">
            <template slot="label"> Mobile phone </template>
            <vue-tel-input
              v-model.trim="form.mobilePhone"
              defaultCountry="us"
              :inputOptions="options"
              :dropdownOptions="telDdOptions"
              :autoFormat="true"
              :validCharactersOnly="true"
              :value="form.mobilePhone"
              mode="international"
              styleClasses="z-index-2"
            />
            <b-form-invalid-feedback id="input-mobile-phone-live-feedback">
              This is a numeric field
            </b-form-invalid-feedback>

            <b-alert show variant="info" class="mt-2">
              Mobile Phone will be used for notifications
            </b-alert>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row> </b-row>
      <b-row> </b-row>

      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6"> </b-col>
        <b-col sm="12" md="3" lg="3" xl="3"> </b-col>
        <b-col sm="12" md="3" lg="3" xl="3">
          <b-btn
            block
            size="lg"
            class="btn btn-success"
            :disabled="$v.form.$invalid || isLoading"
            type="submit"
          >
            Submit
            <b-spinner v-if="isLoading" small variant="white" />
          </b-btn>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { required, minLength, email, numeric } from "vuelidate/lib/validators";
import {
  POST_EMAIL_VALIDATION,
  POST_USER,
} from "@/modules/buyer/registrationForm/actions";
import { mapState } from "vuex";
import { PUT_USER_BUYER } from "./actions";
import { generalMixin } from "@/modules/mixin.js";
import SendPasswordEmail from "@/modules/shared/SendPasswordEmail.vue";

export default {
  props: ["userItem", "refreshData", "hideModal"],
  mixins: [generalMixin],
  components: { SendPasswordEmail },
  computed: {
    ...mapState({
      emailValidation: (state) => state.buyer.buyerStore.emailValidation.data,
      user: (state) => state.buyer.users.user,
      profile: (state) => state.auth.loginStore.profile.data.user,
    }),
    isLoading() {
      return this.user.status == "fetching" ? true : false;
    },
  },

  data() {
    return {
      status: false,
      isAccepTerms: false,
      form: {
        id: null,
        name: null,
        lastName: null,
        email: null,
        officePhoneNumber: null,
        extension: null,
        mobilePhone: null,
      },
      options: {
        type: "tel",
        validCharactersOnly: true,
        maxlength: 14,
      },
      telDdOptions: {
        showDialCodeInList: true,
        showFlags: true,
        showDialCodeInSelection: false,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2),
      },
      lastName: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
      extension: {
        numeric,
      },
      officePhoneNumber: {
        required,
        minLength: minLength(10),
      },
    },
  },
  methods: {
    closeModal(callback) {
      callback();
    },
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async save() {
      await this.verifyEmail();
      if (this.emailValidation.message === "invalid email") {
        return;
      }
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_USER, payload)
        .then(() => {
          this.makeToast("Succes", "User created succesfully", "success");
          this.refreshData();
          this.hideModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast(this.user.error.response.data.message, {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    async update() {
      await this.verifyEmail();
      if (this.emailValidation.message === "invalid email") {
        return;
      }
      const payload = this.formatPayload();
      this.$store
        .dispatch(PUT_USER_BUYER, payload)
        .then(() => {
          this.makeToast("Succes", "User updated succesfully", "success");
          this.refreshData();
          this.hideModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast(this.user.error.response.data.message, {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    async verifyEmail() {
      const payload = {
        userId: this.form.id,
        email: this.form.email,
      };
      await this.$store
        .dispatch(POST_EMAIL_VALIDATION, payload)
        .then(() => {
          if (this.emailValidation.message === "invalid email") {
            this.$root.$bvToast.toast("This email has already been taken", {
              title: "Warning",
              variant: "warning",
            });
          }
        })
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    goTo() {
      if (this.$route.path !== "/") this.$router.push({ path: "/" });
    },
    formatPayload() {
      let role = 2; //ROLE_BUYER

      const payload = {
        id: this.userItem.id ? this.userItem.id : null,

        firstName: this.form.name,
        lastName: this.form.lastName,
        email: this.form.email,
        rolesId: [role],
        userDetails: {
          extension: this.form.extension,
          mobileNumber: this.form.mobilePhone,
          officePhoneNumber: this.form.officePhoneNumber,
          companyId: this.profile.userDetails.companiesId,
        },
      };

      return payload;
    },

    formatForm() {
      this.form.id = this.userItem.id;
      this.form.name = this.userItem.firstName;
      this.form.lastName = this.userItem.lastName;
      this.form.email = this.userItem.email;
      this.form.extension = this.userItem.userDetails.extension;
      this.form.mobilePhone = this.userItem.userDetails.mobileNumber;
      this.form.officePhoneNumber = this.userItem.userDetails.officePhoneNumber;
    },
  },
  mounted() {
    if (this.userItem.id) {
      this.formatForm();
    }
  },
};
</script>

<style scoped>
.append-input {
  max-width: 40%;
}
</style>
