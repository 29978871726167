<template>
  <div>
    <NavBar />
    <router-view></router-view>
  </div>
</template>

<script>
import NavBar from "./NavBar";
export default {
  name: "Default",
  components: { NavBar },
};
</script>

<style></style>
