<template>
  <div>
    <b-navbar variant="blue" class="v-25 text-blue">asg </b-navbar>
    <b-form class="container p-4 mb-3" @submit.stop.prevent="confirmUser">
      <b-row class="mb-3">
        <b-col>
          <!-- Add text to reference -->
          <h2>Create password</h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12" md="12" lg="6" xl="6">
          <b-row class="mb-2">
            <b-col>
              Password
              <span class="text-danger"> *</span>
            </b-col>
          </b-row>
          <b-input-group label-size="md">
            <b-form-input
              size="sm"
              autocomplete="off"
              :type="showPassword ? 'text' : 'password'"
              name="password-input"
              :value="form.password"
              v-model.trim="$v.form.password.$model"
              :state="validateState('password')"
              @change="setValue('password', $event)"
            />
            <b-input-group-append>
              <b-button variant="outline-primary" size="sm">
                <b-icon
                  v-if="showPassword == false"
                  icon="eye"
                  class="mt-1"
                  font-scale="0.99"
                  @click="hanldePasswordVisibility('showPassword', true)"
                />
                <b-icon
                  v-else
                  icon="eye-slash"
                  class="mt-1"
                  font-scale="0.99"
                  @click="hanldePasswordVisibility('showPassword', false)"
                />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <small
            v-bind:class="{
              'text-danger': !$v.form.password.required,
              'text-success': !(
                !$v.form.password.required & !$v.form.password.$dirty
              ),
            }"
          >
            This field is required

            <br />
          </small>
          <small
            v-bind:class="{
              'text-success': minLenght,

              'text-danger': !minLenght,
            }"
          >
            This field must have at least eight characters

            <br />
          </small>
          <small
            v-bind:class="{
              'text-danger': !$v.form.password.containsUppercase,
              'text-success': !(
                !$v.form.password.containsUppercase & !$v.form.password.$dirty
              ),
            }"
          >
            Must have at least one uppercase
            <br />
          </small>
          <small
            v-bind:class="{
              'text-danger': !$v.form.password.containsLowercase,
              'text-success': !(
                !$v.form.password.containsLowercase & !$v.form.password.$dirty
              ),
            }"
          >
            Must have at least one lowercase
            <br />
          </small>
          <small
            v-bind:class="{
              'text-danger': !$v.form.password.containsNumber,
              'text-success': !(
                !$v.form.password.containsNumber & !$v.form.password.$dirty
              ),
            }"
          >
            Must have at least one number
            <br />
          </small>
          <small
            v-bind:class="{
              'text-danger': !$v.form.password.containsSpecial,
              'text-success': !(
                !$v.form.password.containsSpecial & !$v.form.password.$dirty
              ),
            }"
          >
            Must have at least one special character
            <br />
          </small>
        </b-col>
        <b-col sm="12" md="12" lg="6" xl="6">
          <b-row class="mb-2">
            <b-col>
              Confirmation Password
              <span class="text-danger"> *</span>
            </b-col>
          </b-row>
          <b-input-group label-size="md">
            <b-form-input
              required
              size="sm"
              :type="showConfirmPassword ? 'text' : 'password'"
              autocomplete="off"
              name="confirmPassword"
              :value="form.confirmPassword"
              v-model.trim="$v.form.confirmPassword.$model"
              :state="validateState('confirmPassword')"
              @change="setValue('confirmPassword', $event)"
            />

            <b-input-group-append>
              <b-button variant="outline-primary" size="sm">
                <b-icon
                  v-if="showConfirmPassword == false"
                  icon="eye"
                  class="mt-1"
                  font-scale="0.99"
                  @click="hanldePasswordVisibility('showConfirmPassword', true)"
                />
                <b-icon
                  v-else
                  icon="eye-slash"
                  class="mt-1"
                  font-scale="0.99"
                  @click="
                    hanldePasswordVisibility('showConfirmPassword', false)
                  "
                />
              </b-button>
            </b-input-group-append>
          </b-input-group>

          <b-form-invalid-feedback>
            <div v-if="!$v.form.password.sameAsPassword">
              Passwords do not match
            </div>
          </b-form-invalid-feedback>
        </b-col>

        <b-col sm="12" md="9" lg="9" xl="9"> </b-col>
        <b-col sm="12" md="3" lg="3" xl="3">
          <b-button
            block
            class="float-right"
            variant="success"
            type="submit"
            :disabled="$v.form.$invalid"
          >
            Submit
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>
<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { generalMixin } from "../../mixin";
import { PUT_USER_BUYER_PASSWORD } from "./actions";

export default {
  mixins: [generalMixin],
  data() {
    return {
      form: {
        password: "",
        confirmPassword: "",
      },
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  computed: {
    minLenght() {
      return this.form.password.length > 7 ? true : false;
    },
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        containsUppercase: (value) => /[A-Z]/.test(value),
        containsLowercase: (value) => /[a-z]/.test(value),
        containsNumber: (value) => /[0-9]/.test(value),
        containsSpecial: (value) => /[#?!@$%^&*-]/.test(value),
      },
      confirmPassword: {
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    formatPayload() {
      const payload = {
        password: this.form.password,
      };
      return payload;
    },
    resetForm() {
      this.form.password = null;
      this.form.confirmPassword = null;
    },
    async confirmUser() {
      const payload = {
        registrationToken: this.$route.params.token,
        password: this.form.password,
      };
      this.$store
        .dispatch(PUT_USER_BUYER_PASSWORD, payload)
        .then(() => {
          this.makeToast("Succes", "Password changed successfully", "success");
          this.goTo();
        })
        .catch(() => {
          this.$root.$bvToast.toast(this.user.error.response.data.message, {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    goTo() {
      this.$router.push({ path: "/" });
      this.$bvModal.show("login-modal");
    },
    hanldePasswordVisibility(input, isShow) {
      input === "showPassword"
        ? (this.showPassword = isShow)
        : (this.showConfirmPassword = isShow);
    },
  },
};
</script>
