<template>
  <b-form container class="container p-4 mb-3" @submit.stop.prevent="onSubmit">
    <b-row>
      <b-col sm="12" md="12" lg="4" xl="6" class="mb-3">
        <b-row class="mb-2">
          <b-col>
            Password
            <span class="text-danger"> *</span>
          </b-col>
        </b-row>
        <b-input-group label-size="md">
          <b-form-input
            size="sm"
            autocomplete="off"
            :type="showPassword ? 'text' : 'password'"
            name="password-input"
            :value="form.password"
            v-model.trim="$v.form.password.$model"
            :state="validateState('password')"
            @change="setValue('password', $event)"
          />
          <b-input-group-append>
            <b-button
              v-if="showPassword == false"
              variant="outline-primary"
              size="sm"
              @click="hanldePasswordVisibility('showPassword', true)"
            >
              <b-icon icon="eye" class="mt-1" font-scale="0.99" />
            </b-button>
            <b-button
              v-else
              variant="outline-primary"
              size="sm"
              @click="hanldePasswordVisibility('showPassword', false)"
            >
              <b-icon icon="eye-slash" class="" font-scale="0.99" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <small
          v-bind:class="{
            'text-danger': !$v.form.password.required,
            'text-success': !(
              !$v.form.password.required & !$v.form.password.$dirty
            ),
          }"
        >
          This field is required
          <br />
        </small>
        <small
          v-bind:class="{
            'text-success': minLenght,

            'text-danger': !minLenght,
          }"
        >
          This field must have at least 8 characters

          <br />
        </small>
        <small
          v-bind:class="{
            'text-danger': !$v.form.password.containsUppercase,
            'text-success': !(
              !$v.form.password.containsUppercase & !$v.form.password.$dirty
            ),
          }"
        >
          Must have at least one uppercase
          <br />
        </small>
        <small
          v-bind:class="{
            'text-danger': !$v.form.password.containsLowercase,
            'text-success': !(
              !$v.form.password.containsLowercase & !$v.form.password.$dirty
            ),
          }"
        >
          Must have at least one lowercase
          <br />
        </small>
        <small
          v-bind:class="{
            'text-danger': !$v.form.password.containsNumber,
            'text-success': !(
              !$v.form.password.containsNumber & !$v.form.password.$dirty
            ),
          }"
        >
          Must have at least one number
          <br />
        </small>
        <small
          v-bind:class="{
            'text-danger': !$v.form.password.containsSpecial,
            'text-success': !(
              !$v.form.password.containsSpecial & !$v.form.password.$dirty
            ),
          }"
        >
          Must have at least one special character
          <br />
        </small>
      </b-col>
      <b-col sm="12" md="12" lg="6" xl="6">
        <b-row class="mb-2">
          <b-col>
            Confirmation Password
            <span class="text-danger"> *</span>
          </b-col>
        </b-row>
        <b-input-group label-size="md" class="mb-3">
          <b-form-input
            required
            size="sm"
            :type="showConfirmPassword ? 'text' : 'password'"
            autocomplete="off"
            name="confirmPassword"
            :value="form.confirmPassword"
            v-model.trim="$v.form.confirmPassword.$model"
            :state="validateState('confirmPassword')"
            @change="setValue('confirmPassword', $event)"
          />

          <b-input-group-append>
            <b-button
              v-if="showConfirmPassword == false"
              variant="outline-primary"
              size="sm"
              @click="hanldePasswordVisibility('showConfirmPassword', true)"
            >
              <b-icon icon="eye" class="mt-1" font-scale="0.99" />
            </b-button>
            <b-button
              v-else
              variant="outline-primary"
              size="sm"
              @click="hanldePasswordVisibility('showConfirmPassword', false)"
            >
              <b-icon icon="eye-slash" class="" font-scale="0.99" />
            </b-button>
          </b-input-group-append>

          <b-form-invalid-feedback>
            <div v-if="!$v.form.password.sameAsPassword">
              Passwords do not match.
            </div>
          </b-form-invalid-feedback>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="3"></b-col>
      <b-col sm="12" md="12" xl="6">
        <b-button
          :disabled="$v.form.$invalid"
          class="float-right btn-block"
          variant="blue"
          type="submit"
        >
          <b-spinner
            v-if="changePasswordStatus === 'fetching'"
            variant="white"
            label="Spinning"
            small
          />
          <span v-else>Change password</span>
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { POST_SET_NEW_PASSWORD } from "./actions";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { mapState } from "vuex";
export default {
  data() {
    return {
      form: {
        password: null,
        confirmPassword: null,
      },
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  computed: {
    ...mapState({
      changePasswordStatus: (state) =>
        state.auth.forgotPasswordStore.setNewPassword.status,
    }),
    minLenght() {
      return this.form.password && this.form.password.length > 7 ? true : false;
    },
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        containsUppercase: (value) => /[A-Z]/.test(value),
        containsLowercase: (value) => (value ? /[a-z]/.test(value) : false),
        containsNumber: (value) => /[0-9]/.test(value),
        containsSpecial: (value) => /[#?!@$%^&*-]/.test(value),
      },
      confirmPassword: {
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    hanldePasswordVisibility(input, isShow) {
      input === "showPassword"
        ? (this.showPassword = isShow)
        : (this.showConfirmPassword = isShow);
    },
    resetForm() {
      this.form = {
        password: null,
        confirmPassword: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    payload() {
      return {
        token: this.$route.params.token,
        password: this.form.password,
      };
    },
    onSubmit() {
      this.$store
        .dispatch(POST_SET_NEW_PASSWORD, this.payload())
        .then(() => {
          this.resetForm();
          this.$root.$bvToast.toast("Password changed", {
            title: "Success",
            variant: "success",
          });
          this.$router.push({ name: "home" });
          this.$bvModal.show("login-modal");
        })
        .catch(() => {
          this.$root.$bvToast.toast("The password could not be changed", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
  },
};
</script>

<style></style>
