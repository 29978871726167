<template>
  <b-sidebar
    lazy
    :width="widthSidebar"
    :visible="vinInformationSideBar.isShowing"
    id="sidebar-vehicle-details"
    shadow
    right
    @hidden="hideSidebar"
  >
    <b-overlay>
      <VinData />
    </b-overlay>
  </b-sidebar>
</template>

<script>
import VinData from "../registry/VinData.vue";
export default {
  name: "VinInformationSidebar",
  components: { VinData },
  data() {
    return {
      showing: false,
    };
  },
  computed: {
    widthSidebar() {
      return this.$vssWidth > 1280
        ? "50%"
        : this.$vssWidth <= 576
        ? "100%"
        : "50%";
    },
    vinInformationSideBar() {
      return this.$store.state.adminStore.vehicles.listStore.componentsStore
        .vinInformationSideBar;
    },
  },
  methods: {
    hideSidebar() {
      this.$store.commit("SET_IS_SHOWING_VIN_INFORMATION_SIDEBAR", {
        status: false,
        data: null,
      });
    },
  },
};
</script>

<style></style>
