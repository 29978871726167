<template>
  <b-row class="mt-3">
    <b-col sm="12" md="2" lg="2" xl="2" class="my-auto text-center mt-5">
      <b-icon
        font-scale="1"
        icon="circle-fill"
        variant="danger"
        v-if="notification.newNotification == 1"
      >
      </b-icon>
      <strong> {{ notification.registerDate | formatDateTime }} </strong><br />
    </b-col>
    <!-- Vehicle information -->
    <b-col sm="12" md="5" lg="5" xl="5" class="mt-2 mb-3">
      <b-row>
        <b-col class="mt-3">
          <h6>
            Stock:
            <i>{{ notification.vehicle.stockNumber }}</i>
          </h6>
        </b-col>
        <b-col cols=" " class="mt-3 mr-4">
          <h5>
            <i>
              <strong>
                {{ notification.vehicle.vehicleCostDetail.cost | currency }}
              </strong>
            </i>
          </h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row>
            <b-col>
              <b-row>
                <b-col>
                  <div class="mb-2">
                    Make:
                    <i> {{ notification.vehicle.vinNumber.make }} </i>
                  </div>
                </b-col>
                <b-col>
                  <div class="ml-2 mb-2">
                    Year:
                    <i> {{ notification.vehicle.vinNumber.year }}</i>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="mb-2">
                Trim:
                <i> {{ notification.vehicle.vinNumber.trim }}</i>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mb-2 ml-3">
              <b-row>
                Transmission Type:
                <i class="transmissionType-text">
                  {{ notification.vehicle.vinNumber.transmissionType }}
                </i>
              </b-row>
            </b-col>
            <b-col class="ml-2" style="margin-left: -17px">
              Mileage:
              <i>
                {{ notification.vehicle.vehicleInformation.miles }}
              </i>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Location:
              <i> {{ notification.vehicle.location }} </i>
            </b-col>
            <!--    <b-col class="ml-4">
              Release Date:
              <i> {{ notification.vehicle.releaseDate }} </i>
            </b-col> -->
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <!-- Offert -->
    <b-col sm="12" md="3" lg="3" xl="3" class="text-center my-auto mt-5">
      <h5 class="text-center">
        <b-icon
          font-scale="1.5"
          :icon="getIcon"
          :variant="getVariant"
          class="mr-1"
        >
        </b-icon>
        <strong>
          {{ notification.offerPrice | currency }}
        </strong>
      </h5>
      <span class="text-grey">
        {{ notification.buyer.firstName }}
        {{ notification.buyer.lastName }}</span
      >
    </b-col>

    <b-col sm="12" md="2" lg="2" xl="2" class="text-center mt-4 my-auto">
      <b-icon
        font-scale="5"
        icon="arrow-right-circle-fill"
        variant="blue"
        class="mr-1 mt-4 mb-3 btn"
        @click="goTo()"
      >
      </b-icon>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["notification"],
  computed: {
    ...mapState({
      profile: (state) => state.auth.loginStore.profile.data,
    }),
    getIcon() {
      if (
        this.notification.offerPrice >
        this.notification.vehicle.lastHighestOffer
      ) {
        return this.icons.up.icon;
      }
      if (
        this.notification.offerPrice <
        this.notification.vehicle.lastHighestOffer
      ) {
        return this.icons.down.icon;
      }
      return this.icons.same.icon;
    },
    getVariant() {
      if (
        this.notification.offerPrice >
        this.notification.vehicle.lastHighestOffer
      ) {
        return this.icons.up.variant;
      }
      if (
        this.notification.offerPrice <
        this.notification.vehicle.lastHighestOffer
      ) {
        return this.icons.down.variant;
      }
      return this.icons.same.variant;
    },
  },
  data() {
    return {
      icons: {
        up: {
          icon: "arrow-up-circle-fill",
          variant: "success",
        },
        down: {
          icon: "arrow-down-circle-fill",
          variant: "danger",
        },
        same: {
          icon: "dash-circle-fill",
          variant: "warning",
        },
      },
    };
  },
  methods: {
    goTo() {
      this.$router.push({ path: "vehicles/offers" });
    },
  },
};
</script>

<style scoped lang="scss">
i {
  color: #2a2584 !important;
}

.transmissionType-text {
  font-size: 15px;
}
</style>
