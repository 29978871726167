<template>
  <div>
    <b-row>
      <b-col
        v-if="
          (userIsAdmin || userIsAgent || user.id === clientId) &&
          (lastOfferAdmin || lastOfferUser)
        "
      >
        <b-button
          v-if="!counterOfferInput"
          variant="blue"
          block
          @click="showCounterOfferInput"
        >
          Counter-Offer
        </b-button>
        <b-input-group v-if="counterOfferInput">
          <Money
            class="form-control text-center"
            v-model="counterOffer"
            v-bind="money"
          />
          <b-input-group-append>
            <b-button
              variant="blue"
              size="sm"
              @click="sendCounterOffer"
              :disabled="!counterOffer"
            >
              <b-spinner v-if="offerChainStatus === 'fetching'" small />
              <span v-else>Send</span>
            </b-button>
          </b-input-group-append>
          <b-input-group-append>
            <b-button
              variant="danger"
              size="sm"
              @click="closeCounterOfferInput"
            >
              <b-icon icon="x" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <div v-if="!counterOfferInput && !counterOffer">
      <b-row v-if="userIsAdmin || userIsAgent" class="mt-2">
        <b-col>
          <b-button
            :disabled="acceptOfferStatus === 'fetching'"
            variant="success"
            block
            @click="checkHigherOffer"
          >
            <span v-if="acceptOfferStatus === 'fetching'">
              <b-spinner variant="white" small />
            </span>
            <span v-else>
              Accept ({{ lastOfferUser.offerPrice | currency }})
            </span>
          </b-button>
        </b-col>
      </b-row>
      <b-row v-else-if="user.id === clientId && lastOfferAdmin" class="mt-2">
        <b-col>
          <b-button
            :disabled="acceptOfferStatus === 'fetching'"
            variant="success"
            block
            @click="acceptOffer"
          >
            <span v-if="acceptOfferStatus === 'fetching'">
              <b-spinner variant="white" small />
            </span>
            <span v-else>
              Accept ({{ lastOfferAdmin.offerPrice | currency }})
            </span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Money } from "v-money";
import {
  GET_CHECK_HIGHER_OFFER,
  POST_ACCEPT_COUNTER_OFFER,
  GET_OFFERS_BY_USER,
  GET_ACTIVE_OFFERS,
  SHOW_OFFER_SIDEBAR,
  SET_OFFER_SIDEBAR,
  SET_VEHICLE_SOLD,
} from "../actions";
import { POST_VEHICLE_MAKE_AN_OFFER } from "../../list/components/actions";
import { vehicleOffersMixins } from "../mixins";
import { generalMixin } from "@/modules/mixin";
export default {
  components: { Money },
  mixins: [vehicleOffersMixins, generalMixin],
  props: [
    "clientId",
    "vehicleId",
    "lastOfferUser",
    "lastOfferAdmin",
    "offerPrice",
    "offerChain",
  ],
  computed: {
    ...mapState({
      offerChainStatus: (state) =>
        state.adminStore.vehicles.offersStore.offerChain.status,
      acceptOfferStatus: (state) =>
        state.adminStore.vehicles.offersStore.acceptOffer.status,
      user: (state) => state.auth.loginStore.profile.data.user,
    }),
  },
  data() {
    return {
      counterOfferInput: false,
      counterOffer: 0.0,
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "USD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
    };
  },
  methods: {
    showCounterOfferInput() {
      this.counterOfferInput = !this.counterOfferInput;
    },
    closeCounterOfferInput() {
      this.counterOffer = 0.0;
      this.counterOfferInput = false;
    },
    sendCounterOffer() {
      const payload = {
        vehiclesId: this.vehicleId,
        clientId: this.clientId,
        offerPrice: this.counterOffer,
      };
      this.$store
        .dispatch(POST_VEHICLE_MAKE_AN_OFFER, payload)
        .then(({ data }) => {
          this.$root.$bvToast.toast("Counter-Offer sent", {
            title: "Success",
            variant: "success",
          });
          let offer = this.counterOffer;
          const { updatedAt } = data;
          this.$emit("setUserOffer", offer);
          this.$emit("setOfferDate", updatedAt);
          this.closeCounterOfferInput();
          this.$emit("getOfferChain");
        })
        .catch(() => {
          this.$root.$bvToast.toast("The Counter-Offer could not be sent", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    showConfirmAcceptOffer() {
      return this.$bvModal.msgBoxConfirm(
        "There is a higher offer for this vehicle. Do you want to continue?",
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: "YES",
          cancelTitle: "NO",
          cancelVariant: "danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        }
      );
    },
    async checkHigherOffer() {
      let offerId =
        this.userIsAdmin || this.userIsAgent
          ? this.lastOfferUser.id
          : this.lastOfferAdmin.id;

      this.$store
        .dispatch(GET_CHECK_HIGHER_OFFER, { offerId })
        .then(async ({ message }) => {
          switch (message) {
            case "There is a higher offer for this vehicle. Do you want to continue?.":
              if (!(await this.showConfirmAcceptOffer())) {
                return;
              } else {
                this.acceptOffer();
              }
              break;
            case "No higher bid.":
              if (!(await this.showAcceptOfferConfirmation())) {
                return;
              } else {
                this.acceptOffer();
              }
              break;
          }
        })
        .catch(() => {
          this.toastMessage(
            "The highest bid could not be queried",
            "Warning",
            "warning"
          );
        });
    },
    getActiveOffers() {
      const payload = {
        action: GET_ACTIVE_OFFERS,
        status: 1,
      };
      this.$store.dispatch(GET_OFFERS_BY_USER, payload);
    },
    async acceptOffer() {
      if (this.userIsBuyer || this.userIsClient) {
        if (!(await this.showAcceptOfferConfirmation())) {
          return;
        }
      }
      let offerId =
        this.userIsAdmin || this.userIsAgent
          ? this.lastOfferUser.id
          : this.lastOfferAdmin.id;

      const payload = {
        id: offerId,
        vehiclesId: this.vehicleId,
      };
      this.$store
        .dispatch(POST_ACCEPT_COUNTER_OFFER, payload)
        .then(() => {
          this.$root.$bvToast.toast("Offer accepted", {
            title: "Success",
            variant: "success",
          });
          this.$store.commit(SHOW_OFFER_SIDEBAR, false);
          this.$store.commit(SET_OFFER_SIDEBAR, null);
          this.$store.commit(SET_VEHICLE_SOLD, this.vehicleId);
          if (this.userIsBuyer) {
            this.getActiveOffers();
          } else {
            this.getOffers();
          }
        })
        .catch(() => {
          this.toastMessage(
            "Offer could not be accepted",
            "Warning",
            "warning"
          );
        });
    },
  },
};
</script>

<style>
@media screen and (max-width: 400px) {
  .modal-dialog {
    width: 100%;
    padding-right: 30px;
  }

  .modal-content {
    height: auto;
    min-height: 1%;
    border-radius: 0;
  }
}
</style>
