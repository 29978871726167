var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Nav',{on:{"set-status":_vm.setType}}),_c('div',{staticClass:"container-fluid mt-1"},[_c('b-row',{staticClass:"mb-4 mr-1 ml-1"},[_c('b-col',{staticClass:"d-flex justify-content-center mt-1",attrs:{"xs":"12","sm":"12","md":"12","lg":"4","xl":"4"}}),_c('b-col',{staticClass:"mt-1",attrs:{"xs":"12","sm":"12","md":"12","lg":"4","xl":"4"}},[_c('b-input-group',{staticClass:"shadows rounded-right rounded-left align-middle bg-purple-blue",attrs:{"size":"sm"}},[_c('b-form-input',{staticClass:"filter-input rounded-right rounded-left bg-purple-blue",attrs:{"id":"filterInput","type":"search"},on:{"change":function($event){return _vm.getData($event)}},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',{staticClass:"rounded-right rounded-left"},[_c('b-icon',{staticClass:"h5 m-2 align-middle",attrs:{"icon":"search","variant":"pink"}})],1)],1)],1),_c('b-col',{staticClass:"text-center mt-1",attrs:{"xs":"12","sm":"12","md":"12","lg":"4","xl":"4"}},[_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.showModal()}}},[_c('b-icon',{staticClass:"mt-1",attrs:{"icon":"plus"}})],1)],1)],1),_c('b-table',{attrs:{"show-empty":"","bordered":"","small":"","stacked":"md","head-variant":"light","items":_vm.users.data ? _vm.users.data.data : [],"fields":_vm.fields,"tbody-tr-class":"","busy":_vm.users.status == 'fetching'},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"yellow"}})],1)]},proxy:true},{key:"cell(auctionAccessNumber)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.userDetails.auctionAccessNumber)+" "),(item.userDetails.auctionAccessNumber)?_c('b-button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
              item.userDetails.auctionAccessNumber
                ? item.userDetails.auctionAccessNumber
                : ''
            ),expression:"\n              item.userDetails.auctionAccessNumber\n                ? item.userDetails.auctionAccessNumber\n                : ''\n            ",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.copyAuctionNumber),expression:"copyAuctionNumber",arg:"success"}],attrs:{"variant":"none"}},[(_vm.auctionAccessNumberCopied)?_c('b-icon',{attrs:{"icon":"clipboard-check"}}):_c('b-icon',{attrs:{"icon":"clipboard"}})],1):_vm._e()],1)]}},{key:"cell(company)",fn:function(ref){
            var item = ref.item;
return [(item.userDetails.company)?_c('span',[_vm._v(_vm._s(item.userDetails.company.companyName))]):_c('div',[_c('CompanySelect',{attrs:{"refreshData":_vm.getData,"user":item,"companyId":item.userDetails.companiesId}})],1)]}},{key:"cell(actions)",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('b-form-group',[_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"h5 m-2 cursor-pointer",attrs:{"title":"Delete","icon":item.status == 0 ? 'toggle-off' : 'toggle-on'},on:{"click":function($event){item.status == 0 ? _vm.activateItem(item) : _vm.deleteItem(item)}}}),(_vm.type == 'preRegistration')?_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"h5 m-2 cursor-pointer",attrs:{"title":"Send email","icon":"envelope"},on:{"click":function($event){return _vm.sendEmailCompanyForm(item)}}}):_vm._e(),(
                _vm.type == 'inReview' ||
                _vm.type == 'active' ||
                _vm.type == 'preRegistration'
              )?_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"h5 m-2 cursor-pointer",attrs:{"title":"Details","icon":"eye"},on:{"click":function($event){return _vm.showModal(item)}}}):_vm._e(),(_vm.type == 'preRegistration' || _vm.type == 'inReview')?_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"h5 m-2 cursor-pointer",attrs:{"title":"Reject","icon":"x"},on:{"click":function($event){return _vm.rejectUser(item)}}}):_vm._e()],1)],1)]}}])}),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-pagination',{staticClass:"mb-2",attrs:{"value":_vm.users.data ? _vm.users.data.currentPage : 1,"total-rows":_vm.users.data ? _vm.users.data.total : 0,"per-page":_vm.users.data ? _vm.users.data.perPage : 0},on:{"change":function($event){return _vm.changePage($event)}}})],1)],1)],1),_c('b-modal',{ref:"company-modal",attrs:{"id":"company-modal","size":"xl","hide-footer":"","body-class":"pl-0 pr-0","ok-disabled":""}},[_c('ClientForm',{attrs:{"refresh-data":_vm.getData,"hide-modal":_vm.hideModal,"userItem":_vm.userItem}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }