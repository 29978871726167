<template>
  <b-container class="mt-5">
    <Progress-bar v-if="showProgressBar" :step="1" />

    <b-row class="mt-5">
      <b-col
        class="col-lg-6"
        :class="{
          'justify-content-end': !isSmallScreen,
          'justify-content-center': isSmallScreen,
        }"
      >
        <!--<hr v-if="!isSmallScreen" id="divider" class="shadow" />-->

        <div class="mt-3">
          <b-row>
            <b-col class="d-flex justify-content-center">
              <img
                class="img-logo mb-5 img-fluid"
                src="@/assets/img/logos/CANAM_G.png"
                alt="logo"
              />
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="d-flex justify-content-center">
              <b-form @submit.stop.prevent="searchVinNumber">
                <table>
                  <tr>
                    <th>
                      <b-form-input
                        class="input-border-pink"
                        v-model="vinNumber"
                        type="text"
                        placeholder="Vin Number"
                        required
                      />
                    </th>
                    <th>
                      <b-button
                        :disabled="postVehicleStatus === 'fetching'"
                        type="submit"
                        variant="pink"
                      >
                        <b-spinner
                          v-if="postVehicleStatus === 'fetching'"
                          variant="white"
                          label="Spinning"
                          small
                        ></b-spinner>
                        <span v-else>Go</span>
                      </b-button>
                    </th>
                  </tr>
                </table>
              </b-form>
            </b-col>
          </b-row>
        </div>
      </b-col>

      <b-row class="mt-4">
        <b-col class="col-centered">
          <b-tabs justified>
            <b-tab title="Upload vehicles">
              <UploadFile v-if="showUploadVehiclesFile" :upload="true" />
            </b-tab>
            <b-tab title="Update vehicle status">
              <UploadFile v-if="showUploadVehiclesFile" :update="true" />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import ProgressBar from "./ProgressBar";
import { POST_VEHICLE } from "./actions";
import UploadFile from "./UploadFile";
import { generalMixin } from "@/modules/mixin.js";
import {
  SET_VEHICLES_INFO_FILTERS,
  GET_VEHICLES_INFO,
} from "@/modules/superAdmin/actions";
export default {
  props: {
    showProgressBar: {
      type: Boolean,
      default: true,
    },
    showUploadVehiclesFile: {
      type: Boolean,
      default: true,
    },
    redirectToTable: {
      type: Boolean,
      default: false,
    },
    closeUploadVehicleModal: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [generalMixin],
  components: {
    ProgressBar,
    UploadFile,
  },
  data() {
    return {
      vinNumber: "",
      markets: [],
      locations: [],
      fileMultipleCr: null,
      locationMultipleCr: null,
      vinNumberCr: "",
      locationCr: "",
    };
  },
  computed: {
    isSmallScreen() {
      return this.$vssWidth <= 990;
    },
    ...mapState({
      postVehicleStatus: (state) =>
        state.adminStore.vehicles.carRegistry.vehicle.status,
    }),
  },
  methods: {
    payload() {
      const data = {
        vinNumber: this.vinNumber,
      };
      return data;
    },
    searchVinNumber() {
      this.$store
        .dispatch(POST_VEHICLE, this.payload())
        .then((response) => {
          if (this.userIsAdmin) {
            this.$router.push({
              name: "admin.vehicles.registry.general",
              params: { vin: this.vinNumber },
              props: {
                default: true,
                vehicle: response.data,
              },
            });
          } else if (this.userIsSuperAdmin) {
            this.toastMessage("Vehicle uploaded", "Success", "success");
            if (this.redirectToTable)
              this.$router.push({
                name: "superadmin.vehicles.table",
              });
            if (this.closeUploadVehicleModal) {
              this.$store.commit(SET_VEHICLES_INFO_FILTERS, { reset: true });
              this.$store
                .dispatch(GET_VEHICLES_INFO)
                .then(() => {})
                .catch(() => {});
              this.$bvModal.hide("uploadVehicleModal");
            }
          }
          this.vinNumber = null;
        })
        .catch(() => {
          this.toastMessage(
            "The vehicle could not be registered",
            "Warning",
            "warning"
          );
        });
    },
  },
};
</script>

<style scoped>
#divider {
  border: none;
  border-left: 1px solid hsla(200, 10%, 50%, 100);
  height: 27vh;
  width: 1px;
  background-color: #f2f2f2;
}
.small-container-pink {
  border: solid #df0632 1px;
  border-radius: 10px;
}
.small-container-blue {
  border: solid #35a4dd 1px;
  border-radius: 15px;
}
.input-border-pink {
  border: solid #df0632 2px;
  border-radius: 6px;
}

.input-border-blue {
  border: solid #35a4dd 2px;
  border-radius: 6px;
}
@media screen and (min-width: 1000px) {
  .main-cont {
    padding-left: 30%;
    padding-right: 30%;
  }
}
.img-logo {
  max-width: 242px;
  max-height: 92;
}
</style>
