<template>
  <b-modal id="messaging-policy-modal" size="lg" scrollable>
    <div class="text-justify">
      <h3 style="margin-left: -2px">
        <strong>Message frequency disclosure</strong>
      </h3>

      <p>
        By clicking “Sign Up”, you agree to Canamcar.com and/or Canam Auto Terms
        of Use and Privacy Policy. You consent to receive phone calls and SMS
        messages from Canamcar.com and/or Canam Auto to provide updates on your
        order and/or for marketing purposes. Message frequency depends on your
        activity. You may opt-out by texting “STOP”. Message and data rates may
        apply.
      </p>
    </div>
    <template v-slot:modal-footer="{ cancel }">
      <b-button class="btn btn-pink" @click="closeModal(cancel)">
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  methods: {
    closeModal(callback) {
      callback();
    },
  },
};
</script>

<style></style>
