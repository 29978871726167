var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"container p-4 mb-3",attrs:{"container":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit($event)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"sm":"12","md":"12","lg":"4","xl":"6"}},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_vm._v(" Password "),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])])],1),_c('b-input-group',{attrs:{"label-size":"md"}},[_c('b-form-input',{attrs:{"size":"sm","autocomplete":"off","type":_vm.showPassword ? 'text' : 'password',"name":"password-input","value":_vm.form.password,"state":_vm.validateState('password')},on:{"change":function($event){return _vm.setValue('password', $event)}},model:{value:(_vm.$v.form.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.password, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.password.$model"}}),_c('b-input-group-append',[(_vm.showPassword == false)?_c('b-button',{attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.hanldePasswordVisibility('showPassword', true)}}},[_c('b-icon',{staticClass:"mt-1",attrs:{"icon":"eye","font-scale":"0.99"}})],1):_c('b-button',{attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.hanldePasswordVisibility('showPassword', false)}}},[_c('b-icon',{attrs:{"icon":"eye-slash","font-scale":"0.99"}})],1)],1)],1),_c('small',{class:{
          'text-danger': !_vm.$v.form.password.required,
          'text-success': !(
            !_vm.$v.form.password.required & !_vm.$v.form.password.$dirty
          ),
        }},[_vm._v(" This field is required "),_c('br')]),_c('small',{class:{
          'text-success': _vm.minLenght,

          'text-danger': !_vm.minLenght,
        }},[_vm._v(" This field must have at least 8 characters "),_c('br')]),_c('small',{class:{
          'text-danger': !_vm.$v.form.password.containsUppercase,
          'text-success': !(
            !_vm.$v.form.password.containsUppercase & !_vm.$v.form.password.$dirty
          ),
        }},[_vm._v(" Must have at least one uppercase "),_c('br')]),_c('small',{class:{
          'text-danger': !_vm.$v.form.password.containsLowercase,
          'text-success': !(
            !_vm.$v.form.password.containsLowercase & !_vm.$v.form.password.$dirty
          ),
        }},[_vm._v(" Must have at least one lowercase "),_c('br')]),_c('small',{class:{
          'text-danger': !_vm.$v.form.password.containsNumber,
          'text-success': !(
            !_vm.$v.form.password.containsNumber & !_vm.$v.form.password.$dirty
          ),
        }},[_vm._v(" Must have at least one number "),_c('br')]),_c('small',{class:{
          'text-danger': !_vm.$v.form.password.containsSpecial,
          'text-success': !(
            !_vm.$v.form.password.containsSpecial & !_vm.$v.form.password.$dirty
          ),
        }},[_vm._v(" Must have at least one special character "),_c('br')])],1),_c('b-col',{attrs:{"sm":"12","md":"12","lg":"6","xl":"6"}},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_vm._v(" Confirmation Password "),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])])],1),_c('b-input-group',{staticClass:"mb-3",attrs:{"label-size":"md"}},[_c('b-form-input',{attrs:{"required":"","size":"sm","type":_vm.showConfirmPassword ? 'text' : 'password',"autocomplete":"off","name":"confirmPassword","value":_vm.form.confirmPassword,"state":_vm.validateState('confirmPassword')},on:{"change":function($event){return _vm.setValue('confirmPassword', $event)}},model:{value:(_vm.$v.form.confirmPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.confirmPassword, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.confirmPassword.$model"}}),_c('b-input-group-append',[(_vm.showConfirmPassword == false)?_c('b-button',{attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.hanldePasswordVisibility('showConfirmPassword', true)}}},[_c('b-icon',{staticClass:"mt-1",attrs:{"icon":"eye","font-scale":"0.99"}})],1):_c('b-button',{attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.hanldePasswordVisibility('showConfirmPassword', false)}}},[_c('b-icon',{attrs:{"icon":"eye-slash","font-scale":"0.99"}})],1)],1),_c('b-form-invalid-feedback',[(!_vm.$v.form.password.sameAsPassword)?_c('div',[_vm._v(" Passwords do not match. ")]):_vm._e()])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"xl":"3"}}),_c('b-col',{attrs:{"sm":"12","md":"12","xl":"6"}},[_c('b-button',{staticClass:"float-right btn-block",attrs:{"disabled":_vm.$v.form.$invalid,"variant":"blue","type":"submit"}},[(_vm.changePasswordStatus === 'fetching')?_c('b-spinner',{attrs:{"variant":"white","label":"Spinning","small":""}}):_c('span',[_vm._v("Change password")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }