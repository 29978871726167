<template>
  <div>
    <b-container fluid>
      <ProgressBar :step="3" complete />
    </b-container>

    <b-container class="mt-3">
      <b-card footer-tag="footer">
        <b-card-text class="text-center p-4">
          <div class="mb-4">
            <b-icon icon="check-circle" scale="4" variant="success" />
          </div>

          <h2>Vehicle uploaded successfully</h2>
          <p>
            <small>
              Vin number: <b>{{ $route.params.vin }}</b>
            </small>
          </p>
        </b-card-text>
        <template #footer>
          <div class="d-flex justify-content-between">
            <div class="button-back-section">
              <b-button
                :disabled="status === 'fetching'"
                @click="goBack"
                variant="secondary"
              >
                <b-icon icon="chevron-compact-left" />
                Back
              </b-button>
            </div>
            <div class="button-status-section">
              <b-button
                :disabled="status === 'fetching'"
                class="mr-3"
                @click="updateStatus('Draft')"
                variant="secondary"
              >
                Keep as draft
                <b-icon icon="file-earmark-minus" />
              </b-button>
              <b-button
                :disabled="status === 'fetching'"
                variant="danger"
                class="mr-3"
                @click="showSaleInfoModal()"
              >
                Sold
                <b-icon icon="bag-check" />
              </b-button>
              <b-button
                :disabled="status === 'fetching'"
                variant="blue"
                @click="updateStatus('ReadyToSell')"
                class="button-complete"
              >
                Complete
                <b-icon icon="check-circle" />
              </b-button>
            </div>
          </div>
        </template>
      </b-card>
    </b-container>
    <VehicleSaleInfoModal :vehicleId="vehicle.id" />
  </div>
</template>

<script>
import ProgressBar from "./ProgressBar";
import { registryVehicleMixin } from "./mixins";
import { generalMixin } from "@/modules/mixin";
import VehicleSaleInfoModal from "./components/VehicleSaleInfoModal";
export default {
  components: {
    ProgressBar,
    VehicleSaleInfoModal,
  },
  mixins: [registryVehicleMixin, generalMixin],
  methods: {
    goBack() {
      this.$router.push({
        name: this.userIsAdmin
          ? "admin.vehicles.registry.general"
          : "agent.vehicles.edit.general",
        params: { vin: this.$route.params.vin },
      });
    },
    showSaleInfoModal() {
      this.$bvModal.show("vehicleSaleInformationModal");
    },
  },
  beforeMount() {
    this.getVehicleByVin();
  },
};
</script>

<style lang="scss">
@media (max-width: 600px) {
  .button-back-section {
    position: fixed;
  }
  .button-status-section {
    margin-top: 50px;

    .button-complete {
      margin-top: 12px;
    }
  }
}
</style>
