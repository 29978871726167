<template>
  <b-row>
    <b-col sm="12" md="8" lg="8" xl="8" class="no-opacity text-grey">
      <b-row class="pt-2 pr-0 pl-0 mr-0 ml-0">
        <!-- Make -->
        <b-col sm="6" md="6" lg="6" xl="6" class="pl-0 pr-0">
          <b-dropdown
            :text="make ? make : 'All makes'"
            block
            variant="white"
            class="m-2 mt-3"
          >
            <b-dropdown-item @click="searchModel(null)">
              Any Make
            </b-dropdown-item>

            <div>
              <b-dropdown-item
                v-for="(m, i) of filters.make"
                :key="i"
                @click="searchModel(m)"
              >
                {{ m }}
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </b-col>
        <!-- Model -->
        <b-col sm="6" md="6" lg="6" xl="6" class="pl-0 pr-0">
          <b-dropdown
            :text="this.model ? this.model : 'All models'"
            block
            variant="white"
            class="m-2 mt-3"
            menu-class="scrollable-menu"
          >
            <b-dropdown-item @click="model = null">Any Model</b-dropdown-item>
            <b-dropdown-item
              v-for="(m, i) of vehicleModels"
              :key="i"
              @click="model = m"
            >
              {{ m }}
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
        <b-col sm="12" md="0" lg="2" xl="2" />
      </b-row>
    </b-col>
    <!-- Search button -->
    <b-col sm="12" md="4" lg="4" xl="4" class="text-center my-auto mt-2">
      <button
        type="button"
        class="btn btn-success no-opacity block w-75 mt-3"
        @click="profile ? searchCars() : goToPublicVehicleList()"
      >
        Search
      </button>
    </b-col>
  </b-row>
</template>

<script>
import store from "@/store";
import {
  SET_VEHICLES_FILTERS,
  GET_VEHICLE_FILTERS,
  CLEAR_VEHICLE_MODELS,
  GET_VEHICLE_MODELS,
} from "@/modules/admin/vehicles/list/actions";
export default {
  data() {
    return {
      make: null,
      model: null,
    };
  },
  computed: {
    profile() {
      return store.state.auth.loginStore.profile.data;
    },
    filters() {
      return store.state.adminStore.vehicles.listStore.vehicleFilters.data;
    },

    vehicleModels() {
      return store.state.adminStore.vehicles.listStore.vehicleFilters.models
        .data;
    },
  },
  methods: {
    setVehicleFilters() {
      const payload = {
        make: this.make,
        model: this.model,
      };
      this.$store.commit(SET_VEHICLES_FILTERS, { fields: payload });
    },
    showLoginModal() {
      this.$bvModal.show("login-modal");
      this.setVehicleFilters();
    },
    getFilters() {
      this.$store
        .dispatch(GET_VEHICLE_FILTERS)
        .then(() => {})
        .catch(() => {});
    },

    searchModel(make) {
      if (!make) {
        this.$store.commit(CLEAR_VEHICLE_MODELS);

        this.make = make;
        this.model = null;
        this.trim = null;
      } else {
        this.$store.dispatch(GET_VEHICLE_MODELS, { make }).then(() => {
          this.make = make;
          this.model = null;
          this.trim = null;
        });
      }
    },

    searchCars() {
      this.setVehicleFilters();
      this.profile.user.roles.forEach((role) => {
        switch (role.name) {
          case "ROLE_ADMIN":
            this.$router.push({ name: "admin.vehicles.list" });
            break;
          case "ROLE_AGENT":
            this.$router.push({ name: "agent.vehicles.list" });
            break;
          case "ROLE_CLIENT":
            this.$router.push({ name: "client.car-list" });
            break;
          case "ROLE_BUYER":
            this.$router.push({ name: "buyer.car-list" });
            break;
        }
      });
    },
    goToPublicVehicleList() {
      this.$router.push({ name: "inventory.vehicles" });
    },
  },
  beforeMount() {
    this.getFilters();
  },
};
</script>

<style></style>
