<template>
  <div>
    <b-row class="p-0 m-0">
      <b-col
        class="container bg-image mb-5 p-0 text-white text-center justify-content-center"
      >
        <b-row class="justify-content-center m-0">
          <b-col sm="10" md="6" lg="6" xl="6" class="rem-top-5">
            <h1>
              Are you looking for good deals on Canadian imported vehicles?
            </h1>
          </b-col>
        </b-row>

        <!-- Vehicles Filters -->
        <b-row class="justify-content-md-center rem-top-5 pl pr-0 ml-0 mr-0">
          <b-col sm="12" md="8" lg="8" xl="8" class="bg-white-opacity p-5">
            <VehiclesFilters />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- about us -->
    <b-row class="p-0 m-0 mt-5 mb-5" id="about-us">
      <b-col sm="12" md="6" lg="6" xl="6" class="text-center my-auto">
        <img src="@/assets/img/logos/CANAM_G.png" class="img w-85 my-auto" />
        <!--         <img src="@/assets/img/logos/multi-auto.jpg" class="img w-85 my-auto" />
 -->
      </b-col>
      <b-col sm="12" md="6" lg="6" xl="6" class="mt-2">
        <div class="container">
          <h2 class="text-purple">
            <b>Great deals on vehicles imported from Canada</b>
          </h2>
          <br />
          <h3 class="text-purple">
            The easiest way to buy a Canadian vehicle in the US
          </h3>
          <br />
          <p class="text-justify">
            Choose from a great selection of US-landed vehicles, each with their
            price established in US dollars. In addition, a Manheim inspected
            vehicle condition report is attached to every vehicle to assure the
            buyer’s peace of mind. Making a purchase is easy, secure and hassle
            free!
          </p>
          <p class="text-justify">
            We are associated with one of Canada’s largest long-term lease
            companies which provides us access to a huge off-lease inventory.
            Furthermore, our team of specialized vehicle buyers has access to
            the majority of car dealers in Canada.
          </p>
          <p class="text-justify">
            We make sure to continuously add new and exciting deals to our
            fleet, so make sure to stay up to date with CANAM’s newest offers.
          </p>
          <p class="text-justify">
            Transparency, integrity and respect form the foundation for CANAM’s
            international success.
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row class="pr-0 pl-0 m-0 pt-5 pb-5" style="background-color: black">
      <b-col cols="12">
        <CarsSection class="mt-3" />
      </b-col>
    </b-row>
    <!-- Devices -->
    <b-row class="p-0 mt-5 mr-0 ml-0">
      <b-col sm="12" md="6" lg="6" xl="6" class="my-auto mt-2 mb-3">
        <b-container>
          <h2 class="text-purple">
            <b>The premier exporter of used vehicles in Canada</b>
          </h2>
          <p class="text-justify mb-3 mt-3">
            Founded in 2012, Canam rapidly established itself as the leader in
            the used vehicle import/export industry. The CANAM platform has
            proven to be the choice alternative to auctions for American
            retailers wishing to enhance their business opportunities. Canam
            handles every transaction from beginning to end and does so with a
            decade old expertise.
          </p>
          <p class="text-justify mb-3 mt-3">
            Those purchasing from the US also benefit as they are choosing from
            Manheim inspected vehicles with a sale price set in US dollars.
          </p>
          <p class="text-justify mb-3 mt-3">
            Our team of experts has helped you grow your businesses for the past
            decade and our new platform will make it easier than ever before.
          </p>
          <p class="text-justify mt-3 mb-3">
            Transparency, integrity and respect form the foundation for CANAM’s
            international success.
          </p>
        </b-container>
        <br />
        <div class="container">
          <h2 class="text-purple"><b>Online Marketplace </b></h2>

          <p class="text-justify mb-3 mt-3">
            CANAM is a digital vehicle marketplace designed to efficiently
            function on any device for the user’s comfort. This will allow car
            dealerships to navigate our extensive inventory anywhere using
            smartphones, tablets, or computers.
          </p>
        </div>
      </b-col>
      <b-col sm="12" md="6" lg="6" xl="6" class="text-center">
        <img src="@/assets/img/canam-devices.png" class="img-fluid w-85" />
      </b-col>
    </b-row>
    <b-navbar variant="blue" class="p-4"> </b-navbar>

    <b-row class="mx-0">
      <b-col sm="6" md="3" lg="3" xl="3" class="text-center mt-5">
        <img
          src="@/assets/img/logos/CANAM3.png"
          class="img-fluid w-50"
          alt="..."
        />
      </b-col>
      <b-col sm="6" md="3" lg="3" xl="3" class="my-auto text-center">
        <div class="mt-5">
          <br />
          <h5><a href="#about-us">About us</a></h5>
          <br />
          <h5>FAQ</h5>
          <br />
        </div>
      </b-col>

      <b-col sm="6" md="6" lg="6" xl="6" class="mt-5">
        <b-container>
          <b-row>
            <b-col sm="12" md="3" lg="3" xl="3"></b-col>
            <b-col sm="12" md="6" lg="6" xl="6" align-self="center">
              <ContactForm />
            </b-col>
            <b-col sm="12" md="3" lg="3" xl="3"></b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col cols="12" class="text-center mb-3 mt-5">
        <b-row>
          <b-col sm="12" md="6" lg="6" xl="6"> Copyright 2021 Canam </b-col>
          <b-col sm="12" md="6" lg="6" xl="6">
            <a href="https://propulsoft.ca/" class="ml-2">
              Powered by Propulsoft.ca
            </a>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="bg-pink v-25">
        <div class="text-center">
          <small style="color: white">
            CANAM AUTO 4266 DOVE RD. SUITE C PORT HURON 48060 MICHIGAN
            <a href="tel: +1 833 396 1906" style="color: white">
              +1 833 396 1906
            </a>
          </small>
        </div>
      </b-col>
    </b-row>

    <b-modal id="banner-modal" hide-footer hide-header size="xl" centered>
      <b-col cols="12">
        <img src="../../assets/img/banner.png" class="w-100" />
      </b-col>
    </b-modal>
  </div>
</template>

<script>
import CarsSection from "./components/CarsSection.vue";
import VehiclesFilters from "./components/VehiclesFilters.vue";
import ContactForm from "./components/ContactForm.vue";
import { generalMixin } from "@/modules/mixin";
export default {
  components: { CarsSection, VehiclesFilters, ContactForm },
  mixins: [generalMixin],
  mounted() {
    /*     this.showBanner()
     */
  },
  methods: {
    showBanner() {
      /* wait 1 seg and show banner */
      setTimeout(() => {
        this.$bvModal.show("banner-modal");
      }, 1000);
    },
  },
};
</script>

<style scoped>
.bg-image {
  background-image: url("../../assets/img/cam_1.png");
  min-height: 70vh;
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 576px) {
  .bg-image {
    height: 95vh;
  }
}

.bg-white-opacity {
  background-color: rgb(255, 255, 255, 0.7);
}

.w-85 {
  max-width: 85% !important;
}

.border-r-purple {
  border-right: none;
}

@media (min-width: 576px) {
  .border-r-purple {
    border-right: 2px solid #1c133e;
  }
}

.scrollable-menu {
  height: auto;
  max-height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.rem-top-5 {
  margin-top: 5rem !important;
}

.vertical-center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 280px) {
  h1 {
    font-size: 1.8rem !important;
  }
}
</style>
