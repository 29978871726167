<template>
  <b-form @submit.stop.prevent="onSubmit">
    <b-row class="justify-content-center">
      <b-col xl="6" lg="5" md="6" sm="8" class="mb-5">
        <img src="@/assets/img/logos/CANAM_G.png" class="img-fluid mx-auto logo" />
      </b-col>
    </b-row>
    <br />
    <b-row class="justify-content-center">
      <b-col xl="8" lg="5" md="6" sm="8" class="text-center">
        <img src="@/assets/img/icons/user-3.png" class="img-fluid mx-auto user-icon" />
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col xl="6" lg="6" md="6" sm="8" class="mt-1">
        <b-form-input size="lg" placeholder="Email" class="form-input" :formatter="lowerCaseFormatter"
          v-model.trim="$v.form.email.$model" :state="validateState('email')" />
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col xl="6" lg="6" md="6" sm="8" class="mt-2">
        <b-input-group>
          <b-input :type="showPassword ? 'text' : 'password'" size="lg" placeholder="Password" :value="form.password"
            v-model.trim="$v.form.password.$model" :state="validateState('password')" />
          <b-input-group-append>
            <b-button v-if="showPassword == false" variant="outline-primary" size="sm"
              @click="hanldePasswordVisibility(true)">
              <b-icon icon="eye" class="mt-1" font-scale="0.99" />
            </b-button>
            <b-button @click="hanldePasswordVisibility(false)" v-else variant="outline-primary" size="sm">
              <b-icon icon="eye-slash" class="mt-1" font-scale="0.99" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-center mt-3">
      <b-col xl="4" lg="4" md="6" sm="8" class="">
        <b-button block size="lg" :disabled="$v.form.$invalid || loginStatus === 'fetching'" @click="onSubmit()"
          class="btn btn-success block justify-content-center" style="margin-right: 42%" type="submit">
          <b-spinner v-if="loginStatus === 'fetching'" variant="white" label="Spinning" />
          <span v-else> Log In </span>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col class="text-center" @click="close">
        <router-link to="/auth/forgotPassword">Forgot Password?</router-link>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col class="text-center">
        <b-button @click="close" variant="none">
          <router-link to="/buyer/create">
            <h5>Sign up</h5>
          </router-link>
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { AUTH_LOGIN } from "./actions";
import { GET_NOTIFICATIONS } from "../../admin/notifications/actions";
import appRoles from "../../../helpers/appRoles";
import { mapState } from "vuex";
export default {
  name: "LoginForm",
  data() {
    return {
      modalId: "login-modal",
      form: { email: null, password: null },
      showPassword: false,
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.auth.loginStore.profile.data.user,
      loginStatus: (state) => state.auth.loginStore.profile.status,
    }),
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    close() {
      this.$bvModal.hide(this.modalId);
    },
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    hanldePasswordVisibility(isShow) {
      this.showPassword = isShow;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null,
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.form.marketPlace = "CANAM";
      this.$store
        .dispatch(AUTH_LOGIN, this.form)
        .then(async (response) => {
          this.$root.$bvToast.toast("Welcome to Canam", {
            title: "Login success",
          });
          this.resetForm();
          this.handleRedirect(response.user.roles[0].name);
          this.$bvModal.hide(this.modalId);
          await this.getNotifications();
        })
        .catch((error) => {
          let message = "Warning";
          console.log(error);
          if (error.status === 400) {
            switch (error.data.message) {
              case "Authentication failed":
                message = "Invalid login credentials.";
                break;
              case "Company not active yet":
                message = "Company not active yet.";
                break;
              case "Company has been rejected":
                message =
                  "Sorry, your company has been rejected. You don’t have access to this platform.";
                break;
              case "User Inactive":
                message = "User Inactive.";
                break;
              case "The company has been rejected or not validated":
                message = "The company has been rejected or not validated.";
                break;
            }
          }
          this.$root.$bvToast.toast(message, {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    handleRedirect(authority) {
      const userRoles = this.profile.roles.find((r) => r.name === authority);
      const roleAuthority = appRoles.find(
        (role) => role.authority === userRoles.name
      );
      this.$router.push({ name: roleAuthority.mainRoute });
    },
    async getNotifications() {
      await this.$store
        .dispatch(GET_NOTIFICATIONS, {})
        .then(() => { })
        .catch(() => { });
    },
    showBanner() {
      /* wait 1 seg and show banner */
      setTimeout(() => {
        this.$bvModal.show("banner-modal");
      }, 1000);
    }
  },
};
</script>

<style lang="scss" scoped>
.modal-header {
  border-bottom: 0 none;
}

.logo {
  margin-bottom: -5%;
}

.user-icon {
  margin-top: -10%;
}

.forgot-password {
  margin-left: 41%;
}

@media screen and (max-width: 800px) {
  .user-icon {
    margin-left: 50%;
  }

  .btn-main {
    margin-right: 20%;
  }

  .forgot-password {
    margin-left: 30%;
  }
}

.border-r-purple {
  border-right: none;
}

@media (min-width: 576px) {
  .border-r-purple {
    border-right: 2px solid #1c133e;
  }
}

.mr-4-5 {
  margin-right: 4.5rem !important;
}
</style>
