<template>
  <b-modal
    modal-class="modal-fullscreen"
    centered
    scrollable
    id="checks-modal"
    ref="modal"
    title="Checks"
    @show="getChecks(true, vehicle)"
    @hidden="resetCheckForm()"
  >
    <CheckRegistrationForm ref="CheckRegistrationForm" :vehicle="vehicle" />

    <b-list-group v-if="checks.status == 'fetching'" class="mt-2">
      <b-list-group-item class="text-center">
        <b-spinner variant="blue" />
      </b-list-group-item>
    </b-list-group>

    <b-list-group v-else class="mt-2 mb-2 check-list" tag="ul">
      <b-list-group-item
        v-if="checks.data.data && !checks.data.data.length"
        class="text-center"
      >
        <b>No checks to show</b>
      </b-list-group-item>
      <b-list-group-item
        v-for="(check, i) in checks.data.data"
        :key="i"
        class="flex-column align-items-start"
        tag="li"
      >
        <small class="text-muted">
          {{ check.registerDate | formatDateTime }} - {{ check.user.lastName }}
          {{ check.user.firstName }}
        </small>

        <div class="mt-1 mb-1"><b>Date:</b> {{ check.date | formatDate }}</div>

        <div class="mt-1 mb-1">
          <b-row>
            <b-col> <b>Number:</b> {{ check.number }} </b-col>
            <b-col>
              <div class="text-right">
                <span
                  class="dot"
                  :class="[check.status ? 'dot-success' : 'dot-danger']"
                />
              </div>
            </b-col>
          </b-row>
        </div>

        <h3 class="mb-1">
          <i>{{ check.amount | currency }}</i>
        </h3>
      </b-list-group-item>
    </b-list-group>

    <template v-slot:modal-footer="{ close }">
      <b-pagination
        :value="checks.data.currentPage"
        :total-rows="checks.data.total"
        :per-page="checks.data.perPage"
        @change="changePage($event)"
        class="mt-2"
      />
      <div class="justify-content-end">
        <b-button
          variant="outline-secondary"
          class="mr-2"
          @click="() => close()"
        >
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import CheckRegistrationForm from "./RegistrationForm";
import { SET_VEHICLES_CHECKS_FILTERS } from "../actions";
import { superAdminMixin } from "../../mixins";
export default {
  mixins: [superAdminMixin],
  components: { CheckRegistrationForm },
  computed: {
    ...mapState({
      checks: (state) => state.superAdminStore.componentStore.checks,
      vehicle: (state) => state.superAdminStore.vehicle,
    }),
  },
  methods: {
    changePage(page) {
      this.$store.commit(SET_VEHICLES_CHECKS_FILTERS, {
        fields: { page },
      });
      this.getChecks(false, this.vehicle);
    },
    resetCheckForm() {
      console.log(this.$refs.modal.$refs);
    },
  },
};
</script>

<style scoped>
.check-list {
  max-height: 500px;
  overflow-y: auto;
}
.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}
.dot-danger {
  background-color: #ca0707;
}
.dot-success {
  background-color: #19c631;
}
@import "../styles/modals.css";
</style>
