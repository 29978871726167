<!-- @format -->

<template>
  <div>
    <b-navbar variant="blue" class="v-25 text-blue mb-2"><br /> </b-navbar>
    <div class="container mt-3">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="1" cols="12"></b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="10" cols="12">
          <b-form
            class="container mb-3"
            @submit.stop.prevent="form.id ? update() : save()"
          >
            <b-row class="mb-3">
              <b-col>
                <h2 v-if="!form.id" class="text-purple-blue">
                  Welcome {{ form.fullName }}
                </h2>
                <h2 v-else>Company: {{ form.companyName }}</h2>

                <h4 class="text-purple-blue">Company information</h4>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="12" md="12" lg="12" xl="12">
                <b-form-group label-size="md">
                  <template slot="label">
                    Commercial name <span class="text-danger">*</span></template
                  >
                  <b-form-input
                    v-model.trim="$v.form.commercialName.$model"
                    size="md"
                    name="commercialName"
                    id="input-commercial-name"
                    maxlength="100"
                    :value="form.commercialName"
                    :state="validateState('commercialName')"
                    @change="setValue('commercialName', $event)"
                  />
                </b-form-group>
                <b-form-invalid-feedback id="input-commercial-name-feedback">
                  This field is required
                </b-form-invalid-feedback>
              </b-col>

              <b-col sm="12" md="12" lg="12" xl="12">
                <b-form-group label-size="md">
                  <template slot="label">
                    Company name <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    v-model.trim="$v.form.companyName.$model"
                    size="md"
                    name="companyName"
                    maxlength="100"
                    :value="form.companyName"
                    :state="validateState('companyName')"
                    @change="setValue('companyName', $event)"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12" md="12" lg="12" xl="12">
                <b-form-group label-size="md">
                  <template slot="label"> Address </template>
                  <b-form-input
                    v-if="isAddresscompanySelected"
                    v-model="address"
                    size="md"
                    name="street"
                    class="mb-3"
                    maxlength="65"
                    @focus="isAddresscompanySelected = false"
                  />
                  <place-autocomplete-field
                    v-else
                    v-model="address"
                    v-place-autofill:street="form.street"
                    v-place-autofill:city="form.city"
                    v-place-autofill:state="form.state"
                    v-place-autofill:zipcode="form.zip"
                    name="address"
                    api-key="AIzaSyBWj-z1916MmpcimZW3xPlRGXMikForpS4"
                    placeholder="Enter an an address, zipcode, or location"
                    country="us"
                    @autocomplete-select="selectAddress"
                  />
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="12" xl="12">
                <b-form-group label-size="md">
                  <template slot="label"> Address line 2 </template>
                  <b-form-input
                    name="addressLine2"
                    maxlength="200"
                    :value="form.addressLine2"
                    @change="setValue('addressLine2', $event)"
                  />
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> Street </template>
                  <b-form-input
                    v-model.trim="$v.form.street.$model"
                    size="sm"
                    name="street"
                    maxlength="65"
                    :value="form.street"
                    :state="validateState('street')"
                    @change="setValue('street', $event)"
                  />
                  <b-form-invalid-feedback>
                    <div v-if="!$v.form.street.maxLength">
                      Character limit reached
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> ZIP postal code </template>
                  <b-form-input
                    v-model.trim="$v.form.zip.$model"
                    size="sm"
                    name="zip"
                    maxlength="10"
                    :value="form.zip"
                    :state="validateState('zip')"
                    @change="setValue('zip', $event)"
                  />
                  <b-form-invalid-feedback>
                    <div v-if="!$v.form.zip.maxLength">
                      Character limit reached
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> State </template>
                  <b-form-input
                    v-model.trim="$v.form.state.$model"
                    disabled
                    size="sm"
                    name="state"
                    maxlength="250"
                    :value="form.state"
                    :state="validateState('state')"
                    @change="setValue('stateId', $event)"
                  />
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> City </template>
                  <b-form-input
                    v-model.trim="$v.form.city.$model"
                    disabled
                    size="sm"
                    name="city"
                    maxlength="250"
                    :value="form.city"
                    :state="validateState('city')"
                    @change="setValue('city', $event)"
                  />
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> Website </template>
                  <b-form-input
                    v-model.trim="form.webSite"
                    size="sm"
                    name="webSite"
                    placeholder="www.yourSite.com"
                    maxlength="65"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> GST/HST </template>
                  <b-form-input
                    v-model.trim="$v.form.gst.$model"
                    size="sm"
                    name="gst"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> QST </template>
                  <b-form-input
                    v-model.trim="$v.form.qst.$model"
                    size="sm"
                    name="qst"
                  />
                </b-form-group>
              </b-col>

              <!-- NEQ -->
              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label">
                    <b-form-radio-group
                      id="radio-group-2"
                      v-model="typeNumber"
                      name="radio-sub-component1"
                    >
                      <b-form-radio value="NEQ">NEQ</b-form-radio>
                      <b-form-radio value="BusinessNumber">
                        Business number
                      </b-form-radio>
                    </b-form-radio-group>
                    <!--      NEQ <span class="text-danger">*</span> -->
                  </template>
                  <b-form-input
                    size="sm"
                    name="corporationNumber"
                    maxlength="10"
                    @change="setValue('corporationNumber', $event)"
                    v-model.trim="$v.form.corporationNumber.$model"
                    :state="validateState('corporationNumber')"
                    :value="form.corporationNumber"
                  />
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> Dealer license </template>

                  <b-form-input
                    v-model.trim="$v.form.dealerLicense.$model"
                    size="sm"
                    name="dealerLicense"
                    maxlength="50"
                    :value="form.dealerLicense"
                    :state="validateState('dealerLicense')"
                    @change="setValue('dealerLicense', $event)"
                  />
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> Billing Contact name </template>
                  <b-form-input
                    v-model.trim="$v.form.billingContactName.$model"
                    size="sm"
                    name="billingContactName"
                    maxlength="100"
                    :value="form.billingContactName"
                    :state="validateState('billingContactName')"
                    @change="setValue('billingContactName', $event)"
                  />
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> Billing contact email </template>
                  <b-form-input
                    v-model.trim="$v.form.billingContactEmail.$model"
                    size="sm"
                    name="billingContactEmail"
                    maxlength="100"
                    placeholder="example@gmail.com"
                    :formatter="lowerCaseFormatter"
                    :value="form.billingContactEmail"
                    :state="validateState('billingContactEmail')"
                    @change="setValue('billingContactEmail', $event)"
                  />
                  <b-form-invalid-feedback>
                    <div v-if="!$v.form.billingContactEmail.email">
                      This must be a valid email
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label">
                    Billing contact mobile phone
                  </template>
                  <vue-tel-input
                    v-model="form.billingContactMobilePhone"
                    defaultCountry="us"
                    :inputOptions="options"
                    :dropdownOptions="telDdOptions"
                    :autoFormat="true"
                    :validCharactersOnly="true"
                    @change="setValue('billingContactMobilePhone', $event)"
                    :value="form.billingContactMobilePhone"
                    mode="international"
                    styleClasses="z-index-2"
                  />

                  <b-form-invalid-feedback
                    id="input-mobile-phone-live-feedback"
                  >
                    This is a numeric field
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="6" lg="6" xl="6" class="mb-4"> </b-col>
              <b-col sm="12" md="3" lg="3" xl="3" class="mb-4"> </b-col>
              <b-col sm="12" md="3" lg="3" xl="3" class="mb-4">
                <b-button
                  class="float-right"
                  variant="success"
                  type="submit"
                  block
                  :disabled="$v.form.$invalid || isLoading"
                >
                  <b-spinner v-if="isLoading" small variant="white"></b-spinner>
                  <span v-else>Save</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="1" cols="12"></b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { maxLength, email } from "vuelidate/lib/validators";
import { POST_USER_COMPANY, GET_USER_CLIENT } from "./actions";
import { mapState } from "vuex";

export default {
  name: "CompanyForm",
  props: ["companyItem"],

  data() {
    return {
      isAddresscompanySelected: false,
      address: null,
      form: {
        fullName: "",
        id: null,

        officePhoneNumber: null,
        mobileNumber: null,
        extension: null,
        street: "",
        zip: "",
        state: "",
        city: "",
        webSite: "",
        dealerLicense: "",
        billingContactName: "",
        billingContactEmail: "",
        billingContactMobilePhone: null,
        companyName: "",
        addressLine2: "",
        gst: null,
        qst: "",
        corporationNumber: "",
        secondaryEmail: "",
        secondaryMobileNumber: null,
        faxNumber: null,
        companyId: null,
        commercialName: "",
        toBeDelivered: 1,
        userItem: null,
        auctionAccessNumber: null,
      },
      typeNumber: "NEQ",
      options: {
        type: "tel",
        validCharactersOnly: true,
        maxlength: 18,
      },
      telDdOptions: {
        showDialCodeInList: true,
        showFlags: true,
        showDialCodeInSelection: false,
      },
    };
  },
  validations: {
    form: {
      officePhoneNumber: {},
      extension: {},
      mobileNumber: {},
      street: {
        maxLength: maxLength(250),
      },
      zip: {
        maxLength: maxLength(10),
      },
      state: {},
      city: {},

      dealerLicense: {},

      billingContactEmail: {
        email,
      },
      billingContactName: {},
      companyName: {},
      billingContactMobilePhone: {},
      gst: {},
      qst: {},
      corporationNumber: {},
      secondaryEmail: {},
      secondaryMobileNumber: {},
      faxNumber: {},
      companyId: {},
      commercialName: {},
      toBeDelivered: {},
      auctionAccessNumber: {},
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.buyer.buyerStore.user,
    }),
    isLoading() {
      return this.user.status == "fetching" ? true : false;
    },
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_USER_COMPANY, payload)
        .then(async () => {
          await this.$root.$bvToast.toast("Company successfully registered", {
            title: "Success",
            variant: "success",
          });

          setTimeout(() => {
            this.goTo();
          }, 6000);
        })
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },

    formatPayload() {
      const payload = {
        registrationToken: this.$route.params.token
          ? this.$route.params.token
          : null,
        street: this.form.street,
        zip: this.form.zip,
        stateId: this.form.state,
        webSite: this.form.webSite,
        taxid: this.form.taxidOne,
        dealerLicense: this.form.dealerLicense,
        billingContactName: this.form.billingContactName,
        billingContactEmail: this.form.billingContactEmail,
        city: this.form.cityId,
        companyName: this.form.companyName,
        addressLine2: this.form.addressLine2,
      };
      return payload;
    },
    getBuyer() {
      this.$store
        .dispatch(GET_USER_CLIENT, {
          registrationToken: this.$route.params.token,
        })
        .then((response) => {
          console.log(response);
          const user = response.data[0];
          /*           this.form.companyName = user.userDetails.company.companyName;
           */ this.form.fullName = user.firstName + " " + user.lastName;
        })
        .catch((error) => {
          console.log(error);
          this.$root.$bvToast.toast(
            "This link has expired, please contact us to request a new one by email.",
            {
              title: "Warning",
              variant: "warning",
            }
          );
        });
    },
    selectAddress() {
      this.isAddressSelected = true;
    },
    goTo() {
      this.$router.push({ path: "/" });
      this.$bvModal.show("login-modal");
    },

    update() {
      console.log("update method");
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_USER_COMPANY, payload)
        .then(async () => {
          await this.$root.$bvToast.toast("Company successfully updated", {
            title: "Success",
            variant: "success",
          });
          this.$emit("closeModal");
        })
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    formatForm() {
      this.form.id = this.companyItem.id;
      this.form.companyName = this.companyItem.companyName;
      this.form.fullName = this.companyItem.fullName;
      this.form.street = this.companyItem.street;
      this.form.zip = this.companyItem.zip;
      this.form.state = this.companyItem.state;
      this.form.cityId = this.companyItem.city;
      this.form.webSite = this.companyItem.webSite;
      this.form.taxidOne = this.companyItem.taxid;
      this.form.dealerLicense = this.companyItem.dealerLicense;
      this.form.billingContactName = this.companyItem.billingContactName;
      this.form.billingContactEmail = this.companyItem.billingContactEmail;
      this.form.addressLine2 = this.companyItem.addressLine2;
    },
  },
  mounted() {
    console.log("update", this.companyItem);

    if (this.companyItem?.id) {
      this.formatForm();
    } else {
      this.getBuyer();
    }
  },
};
</script>

<style></style>
