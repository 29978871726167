import { mapState } from "vuex";
import {
  CLEAR_VEHICLE_TO_EDIT,
  SET_VEHICLE_VIN,
  GET_VEHICLE_BY_VIN,
  POST_UPDATE_VEHICLE,
  GET_USERS_NICKNAMES,
  GET_LOCATIONS,
} from "./actions";
const registryVehicleMixin = {
  data() {
    return {
      vehicleHasParts: [],
      vehicle: {
        id: null,
        userId: null,
        stockNumber: null,
        releaseDate: null,
        crUrl: null,
        location: null,
        status: null,
        vehicleCostDetail: {
          price: 0.0,
          cost: 0.0,
          reservedPrice: 0.0,
        },
        vehicleInformation: {
          color: null,
          miles: null,
        },
        vinData: {
          doors: null,
          driveType: null,
          engine: null,
          madeIn: null,
          madeInCity: null,
          make: null,
          model: null,
          options: null,
          year: null,
          style: null,
          transmissionType: null,
          trim: null,
          vehicleType: null,
          vinNumber: null,
        },
      },

    };
  },
  computed: {
    ...mapState({
      car: (state) => state.adminStore.vehicles.carRegistry.vehicleItem.data,
      status: (state) =>
        state.adminStore.vehicles.carRegistry.vehicleItem.status,
      usersNicknames: (state) =>
        state.adminStore.vehicles.carRegistry.usersNicknames.data,
      locations: (state) =>
        state.adminStore.vehicles.carRegistry.locations.data,
    }),
  },
  methods: {
    clearVehicleToEdit() {
      this.$store.commit(CLEAR_VEHICLE_TO_EDIT);
    },
    setVehicleData() {
      // Vehicle //
      this.vehicle.id = this.car.id;
      /*   if (
          this.$route.name === "admin.vehicles.registry.general" ||
          this.$route.name === "agent.vehicles.edit.general"
        ) { */
      this.formatVehicleCost();
      this.vehicle.userId = this.car.usersId;
      this.vehicle.stockNumber = this.car.stockNumber;
      this.vehicle.releaseDate = this.car.releaseDate;
      this.vehicle.crUrl = this.car.crUrl;
      this.vehicle.location = this.car.exportLocations?.name;
      this.vehicle.vinData.vinNumber = this.car.vinNumber.vinNumber;

      // Vin Number //
      this.vehicle.vinData.year = this.car.vinNumber.year;
      this.vehicle.vinData.doors = this.car.vinNumber.doors;
      this.vehicle.vinData.make = this.car.vinNumber.make;
      this.vehicle.vinData.model = this.car.vinNumber.model;
      this.vehicle.vinData.trim = this.car.vinNumber.trim;
      this.vehicle.vinData.style = this.car.vinNumber.style;
      this.vehicle.vinData.vehicleType = this.car.vinNumber.vehicleType;
      this.vehicle.vinData.madeIn = this.car.vinNumber.madeIn;
      this.vehicle.vinData.madeInCity = this.car.vinNumber.madeInCity;
      this.vehicle.vinData.driveType = this.car.vinNumber.driveType;
      this.vehicle.vinData.transmissionType = this.car.vinNumber.transmissionType;
      this.vehicle.vinData.engine = this.car.vinNumber.engine;
      this.vehicle.vinData.options = this.car.vinNumber.options;

      // Vehicle Information //
      this.vehicle.vehicleInformation.color = this.car.vehicleInformation.color;
      this.vehicle.vehicleInformation.miles = this.car.vehicleInformation.miles;
      // Vehcile Cost Detail //
      this.vehicle.vehicleCostDetail.price = this.car.vehicleCostDetail.price;
      this.vehicle.vehicleCostDetail.cost = this.car.vehicleCostDetail.cost;


      if (this.car.vehicleCostDetail.fx) {
        if (this.car.vehicleCostDetail.cost === 0) {
          this.vehicle.vehicleCostDetail.costUsd = 0;
        } else {
          this.vehicle.vehicleCostDetail.costUsd = (this.car.vehicleCostDetail.cost + 2500) / this.car.vehicleCostDetail.fx

        }
      } {
        this.vehicle.vehicleCostDetail.costUsd = this.car.vehicleCostDetail.costUsd;
      }


      this.vehicle.vehicleCostDetail.reservedPrice = this.car.vehicleCostDetail.reservedPrice;
      // Vehicle Parts //
      this.vehicleHasParts = this.car.vehicleHasParts;
      /*   } */
    },
    getVehicleByVin() {
      this.vehicleHasParts = [];
      const vinNumber = this.$route.params.vin || this.$store.state.adminStore.vehicles.listStore.componentsStore
        .vinInformationSideBar.data;
      this.$store.commit(SET_VEHICLE_VIN, { fields: { vinNumber, fullInventory: 1 } });
      this.$store
        .dispatch(GET_VEHICLE_BY_VIN)
        .then((response) => {
          if (response.data[0].status === "Sold") {
            this.$router.push({ name: "admin.vehicles.registry" });
            this.$root.$bvToast.toast("Vehicle Sold! It cannot be updated", {
              title: "Warning",
              variant: "warning",
            });
          } else if (response.data[0].status === "Inactive") {
            this.$router.push({ name: "admin.vehicles.registry" });
            this.$root.$bvToast.toast("Vehicle deleted! It cannot be updated", {
              title: "Warning",
              variant: "warning",
            });
          } else {
            this.setVehicleData();
          }
        })
        .catch(() => {
          this.$root.$bvToast.toast("Vehicle information not obtained", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    nextPage() {
      const data = new FormData();
      data.set("id", this.vehicle.id);
      data.set("vehicle", JSON.stringify(this.vehicle));

      if (this.vehicle.stockNumber)
        data.set("stockNumber", this.vehicle.stockNumber);

      if (this.vehicle.releaseDate)
        data.set("releaseDate", this.vehicle.releaseDate);

      if (this.vehicle.userId) data.set("userId", this.vehicle.userId);

      if (this.vehicle.location) data.set("location", this.vehicle.location);
      data.set("marketPlace", "CANAM");
      data.set("crUrl", this.vehicle.crUrl);
      this.$store
        .dispatch(POST_UPDATE_VEHICLE, data)
        .then(() => {
          this.$router.push({
            name: this.userIsAdmin
              ? "admin.vehicles.registry.uploaded"
              : "agent.vehicles.registry.uploaded",
            params: { vin: this.$route.params.vin },
          });
        })
        .catch(() => {
          this.$root.$bvToast.toast(
            "The vehicle information could not be updated",
            {
              title: "Warning",
              variant: "warning",
            }
          );
        });
    },
    sendToVehicleList() {
      this.$router.push({
        name: this.userIsAdmin ? "admin.vehicles.list" : "agent.vehicles.list",
        params: { vin: this.$route.params.vin },
      });
    },
    updateStatus(status) {
      const data = new FormData();
      data.set("id", this.vehicle.id);
      data.set("status", status);
      this.$store
        .dispatch(POST_UPDATE_VEHICLE, data)
        .then(() => {
          let message =
            status === "Inactive"
              ? "Vehicle status changed to Inactive"
              : status === "Sold"
                ? "Vehicle status changed to Sold"
                : "Vehicle updated";
          this.toastMessage(message, "Success", "success");
          this.sendToVehicleList();
        })
        .catch(() => {
          this.toastMessage("Vehicle not updated", "Warning", "warning");
        });
    },
    getUserNickNames() {
      this.$store.dispatch(GET_USERS_NICKNAMES);
    },
    getLocations() {
      this.$store.dispatch(GET_LOCATIONS).then(() => {
        this.locations.forEach((location) => {
          if (location.name === this.vehicle.location) {
            this.vehicle.location = location;
          }
        });
      });
    },
    formatVehicleCost() {
      this.vehicleCost.price = this.vehicle.vehicleCostDetail.price;
      this.vehicleCost.reservedPrice =
        this.vehicle.vehicleCostDetail.reservedPrice;

    },
  },
};

export { registryVehicleMixin };
