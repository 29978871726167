<template>
  <div class="m-4 text-break">
    <b-container class="mt-2">
      <h2>User details:</h2>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4" cols="12">
          <label for=""><b>Full name:</b></label> <br />
          {{ userItem.user.firstName }}
          {{ userItem.user.lastName }}
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4" cols="12">
          <label for=""><b>Email:</b></label> <br />
          {{ userItem.user.email || "------" }}
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4" cols="12">
          <label for=""><b>Mobile phone:</b></label> <br />
          {{ userItem.user.userDetails.mobileNumber || "------" }}
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4" cols="12">
          <label for=""><b>Office phone number:</b></label> <br />
          {{ userItem.user.userDetails.officePhoneNumber || "------" }}
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4" cols="12">
          <label for=""><b>Extension:</b></label> <br />
          {{ userItem.user.userDetails.extension || "------" }}
        </b-col>

        <b-col xs="12" sm="12" md="12" lg="6" xl="6" cols="12"></b-col>
      </b-row>
      <div v-if="userItem.user.status != 0" class="mt-2">
        <h2>Company details:</h2>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="4" xl="4" cols="12">
            <label for=""><b>Company name:</b></label> <br />
            {{ userItem.companyName || "------" }}
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="4" xl="4" cols="12">
            <label for=""><b>Website:</b></label> <br />
            {{ userItem.webSite || "------" }}
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="4" xl="4" cols="12">
            <label for=""><b>State:</b></label> <br />
            {{ userItem.state ? userItem.state : "------" }}
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="4" xl="4" cols="12">
            <label for=""><b>City:</b></label> <br />
            {{ userItem.city ? userItem.city : "------" }}
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="4" xl="4" cols="12">
            <label for=""><b>Street:</b></label> <br />
            {{ userItem.street || "------" }}
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="4" xl="4" cols="12">
            <label for=""><b>Postal code:</b></label> <br />
            {{ userItem.zip || "------" }}
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="4" xl="4" cols="12">
            <label for=""><b>Taxid:</b></label> <br />
            {{ userItem.taxid || "------" }}
          </b-col>

          <b-col xs="12" sm="12" md="12" lg="4" xl="4" cols="12">
            <label for=""><b>Dealer license:</b></label> <br />
            {{ userItem.dealerLicense || "------" }}
          </b-col>

          <b-col xs="12" sm="12" md="12" lg="4" xl="4" cols="12">
            <label for=""><b>Billing contact name:</b></label> <br />
            {{ userItem.billingContactName || "------" }}
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="4" xl="4" cols="12">
            <label for=""><b>Billing contact email:</b></label> <br />
            {{ userItem.billingContactEmail || "------" }}
          </b-col>

          <b-col xs="12" sm="12" md="12" lg="4" xl="4" cols="12"></b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  props: {
    userItem: {},
  },
};
</script>
