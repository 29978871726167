<template>
  <b-sidebar
    lazy
    :width="widthSidebar"
    :visible="showSideBar"
    title="Vehicles Offers"
    shadow
    @shown="showSlider"
    @hidden="clearOffers"
  >
    <div class="header-slider shadow container">
      <b-row>
        <b-col v-if="offersStatus === 'fetching'" lg="12" class="mb-2">
          <b-card class="text-center">
            <b-spinner variant="purple-blue" />
          </b-card>
        </b-col>
        <div v-else-if="offers.data.data && offers.data.data.length">
          <b-button
            variant="success"
            block
            class="mb-2 sticky-top"
            @click="showOffersFullScreen"
          >
            View in full screen
          </b-button>
          <b-col
            lg="12"
            v-for="vehicle in offers.data.data"
            :key="vehicle.id"
            class="mb-2"
          >
            <VehicleCard
              :vehicle="vehicle"
              :viewOffers="true"
              @getOffers="getOffers()"
              :showCRModal="false"
            />
          </b-col>
        </div>
        <b-col v-else lg="12" class="mb-2">
          <b-card class="text-center">
            <strong>No records to show</strong>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-sidebar>
</template>

<script>
import { mapState } from "vuex";
import { vehicleOffersMixins } from "./mixins";
import VehicleCard from "../list/VehicleCard";
export default {
  mixins: [vehicleOffersMixins],
  components: { VehicleCard },
  data() {
    return {};
  },
  computed: {
    widthSidebar() {
      return this.$vssWidth > 1280
        ? "25%"
        : this.$vssWidth <= 576
        ? "100%"
        : "50%";
    },
    ...mapState({
      user: (state) => state.auth.loginStore.profile.data.user,
      showSideBar: (state) =>
        state.adminStore.vehicles.offersStore.offersSideBar.show,
    }),
  },
  methods: {
    showSlider() {
      this.getOffers(false);
    },
    showOffersFullScreen() {
      this.user.roles.forEach((value) => {
        switch (value.name) {
          case "ROLE_ADMIN":
            this.$router.push({ name: "admin.vehicles.offers" });
            break;
          case "ROLE_AGENT":
            this.$router.push({ name: "agent.vehicles.offers" });
            break;
        }
      });
    },
  },
};
</script>

<style></style>
