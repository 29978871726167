<template>
  <div>
    <b-navbar variant="white">
      <b-navbar-brand>
        <b-button variant="white" class="p-0" @click="goBack()">
          <b-icon
            icon="arrow-left-circle"
            variant="purple-bluevvv"
            font-scale="1.7"
          ></b-icon>
        </b-button>
      </b-navbar-brand>
      <b-nav
        class="w-100 d-lg-inline-flex"
        align="end"
        v-if="witdhResponsive !== '100%'"
      >
      </b-nav>
    </b-navbar>
    <b-container class="mt-5 mb-5">
      <b-row class="mx-0 px-0">
        <b-col sm="12" md="6" lg="6" xl="6" v-if="vehicle">
          <VehicleCardGallery :images="images" />

          <CRButton :vehicle="vehicle" class="mt-1" />
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="6"
          class="text-break mt-2"
          v-if="vehicle"
        >
          <h1>
            <i>
              {{ vehicle.vinNumber.year }} {{ vehicle.vinNumber.make }}
              {{ vehicle.vinNumber.model }}
            </i>
          </h1>
          <h2>{{ vehicle.vinNumber.trim }} {{ vehicle.vinNumber.options }}</h2>
          <h3>
            US-landed Canadian vehicle
            <!--   <span v-if="readyToSell"
              >release date: {{ vehicle.releaseDate }}
            </span>
            <span v-else> ready to sell</span> -->
          </h3>
          <b-row>
            <b-col>
              <b-row>
                <b-col>
                  <div class="mb-2">
                    Make:
                    <i>{{ vehicle.vinNumber.make }}</i>
                  </div>
                </b-col>
                <b-col>
                  <div class="mb-2">
                    Year:
                    <i>{{ vehicle.vinNumber.year }}</i>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-row> </b-row>
              <div class="mb-2">
                Model:
                <i>
                  {{ vehicle.vinNumber.model }} {{ vehicle.vinNumber.trim }}
                  {{ vehicle.vinNumber.options }}
                </i>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Mileage:
              <i>{{ vehicle.vehicleInformation.miles | formatNumber }}</i>
            </b-col>
          </b-row>
          <b-row v-if="vehicle.releaseDate">
            <!--  <b-col class="mb-2 mt-2">
              Release Date:
              <i>{{ vehicle.releaseDate | formatDate }} </i>
            </b-col> -->
          </b-row>
          <b-row class="mt-1 mb-2">
            <b-col class="ml-1" v-if="vehicle.vinNumber.style">
              Style:
              <i>{{ vehicle.vinNumber.style }}</i>
            </b-col>
            <b-col class="ml-1" v-if="vehicle.vinNumber.doors">
              Doors:
              <i>{{ vehicle.vinNumber.doors }}</i>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2" v-if="vehicle.vehicleInformation.color">
            <b-col class="ml-1">
              Color:
              <i>{{ vehicle.vehicleInformation.color }}</i>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2" v-if="vehicle.vinNumber.vehicleType">
            <b-col class="ml-1">
              Vehicle Type:
              <i>
                {{ vehicle.vinNumber.vehicleType }}
              </i>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2" v-if="vehicle.vinNumber.driveType">
            <b-col class="ml-1">
              Drive Type:
              <i>
                {{ vehicle.vinNumber.driveType }}
              </i>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2" v-if="vehicle.vinNumber.driveType">
            <b-col class="ml-1">
              Engine:
              <i>
                {{ vehicle.vinNumber.driveType }}
              </i>
            </b-col>
          </b-row>

          <!--   <b-row
          v-if="
            !isHome &&
            vehicle.vehicleCostDetail.cost &&
            user &&
            (userIsAdmin || userIsAgent)
          "
        >
          <b-col class="mb-2">
            Status:
            <strong>
              {{
                vehicle.status === "ReadyToSell"
                  ? "Ready To Sell"
                  : vehicle.status
              }}
            </strong>
          </b-col>
        </b-row> -->
          <b-row class="mb-1">
            <b-col>
              <img
                src="@/assets/img/mapaIcon.png"
                class="img-responsive"
                :id="'tooltip-google-maps-' + vehicle.id"
                style="max-width: 20px"
              />
              Location
              <i>{{ vehicle.location }}</i>
              <b-tooltip
                :target="'tooltip-google-maps-' + vehicle.id"
                triggers="hover"
              >
                <h5 class="mt-2">{{ vehicle.location }}</h5>
                <br />
                <h5>Address</h5>
                44951 County Rd 191, Wellesley Island, NY 13640, EE. UU.
                <br />
                <br />
                <a
                  href="https://goo.gl/maps/uKtbs1u3K6fmPLZG7"
                  target="_blank"
                  class="mt-2"
                  style="color: white; text-decoration: none"
                >
                  View on Google Maps
                </a>
              </b-tooltip>
            </b-col>
          </b-row>

          <b-row
            class="mt-2"
            v-if="vehicle.vinNumber && vehicle.vinNumber.vinNumber"
          >
            <b-col class="text-center">
              <vue-barcode
                style="width: 100%"
                :value="vehicle.vinNumber.vinNumber"
                :options="barcodeOptions"
                class="bar-code"
              />
              <i style="font-size: 13px">{{ vehicle.vinNumber.vinNumber }}</i>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="mt-2 text-center"
              v-if="
                userHasCompany &&
                vehicle.vehicleCostDetail &&
                vehicle.vehicleCostDetail.price &&
                user != null
              "
            >
              <b-row>
                <b-col>
                  <b>Selling Price:</b>
                  <h1>
                    <i>
                      <strong>
                        {{ this.vehicle.vehicleCostDetail.price | currency }}
                      </strong>
                    </i>
                  </h1>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div
            v-if="vehicleAcceptOffer || vehicle.status === 'Sold'"
            class="mt-2"
          >
            <img
              src="@/assets/img/TOOLATE.png"
              alt="sold-image"
              class="img-fluid"
            />
          </div>
          <div v-else>
            <b-row v-if="user" class="mt-3">
              <b-col class="mb-1">
                <b-button
                  variant="purple-blue"
                  block
                  @click="showVehicleOffers()"
                  class="shadow"
                >
                  View Offers
                </b-button>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-else-if="!isHome">
              <b-col>
                <b-button
                  v-if="
                    vehicle.status !== 'Inactive' &&
                    (userIsAdmin ||
                      (userIsAgent && vehicle.usersId === user.id))
                  "
                  variant="purple-blue"
                  block
                  class="mt-1"
                  @click="editVehicle(vehicle.vinNumber.vinNumber)"
                >
                  <b-icon icon="pencil" />
                </b-button>
              </b-col>
            </b-row>

            <b-row
              v-if="!isHome && (userHasOffer || vehicle.offerClient)"
              class="mt-3"
            >
              <b-col>
                <b-button
                  variant="blue"
                  block
                  size="sm"
                  @click="changeOfferTab(vehicle.offerClient.mainOffer)"
                >
                  <h5 class="mt-1">
                    Current Offer:
                    <span v-if="vehicle.offerClient">
                      {{ vehicle.offerClient.offerPrice | currency }}
                    </span>
                    <span v-else-if="userHasOffer">
                      {{ currentOffer | currency }}
                    </span>
                  </h5>
                </b-button>
              </b-col>
            </b-row>
            <MakeAnOffer
              v-if="
                !isHome &&
                !userHasOffer &&
                !vehicle.offerClient &&
                (userIsBuyer || userIsClient)
              "
              :vehicleId="this.vehicle.id"
              :price="this.vehicle.vehicleCostDetail.price"
              :userPending="!userHasCompany"
              @setUserHasOffer="setUserHasOffer($event)"
            />
          </div>
        </b-col>
      </b-row>
      <CRPageModal
        v-if="vehicle"
        :id="this.vehicle.id"
        :crUrl="this.vehicle.crUrl"
      />
    </b-container>
    <b-navbar class="mt-3 py-3 block" toggleable="lg" variant="white">
      <b-nav class="w-100 d-lg-inline-flex" align="end"> </b-nav>
    </b-navbar>
  </div>
</template>

<script>
import { GET_VEHICLE, SET_ONE_VEHICLE_FILTERS } from "./actions";
import { SET_VEHICLES_FILTERS } from "@/modules/admin/vehicles/list/actions";

import CRPageModal from "@/modules/admin/vehicles/list/components/CRPageModal";
import MakeAnOffer from "@/modules/admin/vehicles/list/components/MakeAnOffer";
import { generalMixin } from "@/modules/mixin";
import { mapState } from "vuex";
import VehicleCardGallery from "../admin/vehicles/list/VehicleCard/VehicleCardGallery.vue";
import CRButton from "../admin/vehicles/list/VehicleCard/CRButton.vue";
export default {
  name: "VehicleDetail",
  components: { CRPageModal, MakeAnOffer, VehicleCardGallery, CRButton },
  mixins: [generalMixin],

  data() {
    return {
      damages: [],
      interior: [],
      exterior: [],
      index: 0,
      damagesDots: [],
      imgError: "",
      imgNotFound: false,
      intervalPhotos: null,
      isGalleryGoingBack: false,
      tab: 0,
      barcodeOptions: {
        format: "CODE39",
        with: 10,
        height: 60,
        displayValue: false,
        marginTop: 10,
      },
      userHasOffer: false,
      currentOffer: null,
    };
  },
  computed: {
    ...mapState({
      vehicle: (state) => state.vehicle.vehicle.data,
      vehicleStatus: (state) => state.vehicle.vehicle.status,
      profile: (state) => state.auth.loginStore.profile.data,
    }),
    isHome() {
      return this.$route.name === "home" ? true : false;
    },
    readyToSell() {
      let today = new Date().toLocaleDateString();
      let release = new Date(this.vehicle.releaseDate);

      return release > today;
    },
    vehicleAcceptOffer() {
      return this.$store.state.adminStore.vehicles.offersStore.vehiclesSold.vehicles.find(
        (vehicle) => vehicle.vehicleId === this.vehicle.id
      );
    },
    images() {
      if (this.exterior.length === 0) {
        return this.formatImages(this.interior);
      } else {
        return this.formatImages(this.exterior);
      }
    },
  },

  methods: {
    selectImage(val) {
      this.imgError = "";
      this.imgNotFound = false;
      this.index = val.index;
      this.tab = val.tab;
      this.imgNotFound = val.imgNotFound;
    },
    changeTab(tab) {
      this.tab = tab;
      this.index = 0;
    },
    setDefaultPhotosState() {
      this.exterior = [];
      this.interior = [];
      this.damagePhotos = [];
      this.damages = [];
    },
    setImages() {
      this.setDefaultPhotosState();
      this.vehicle?.vehicleHasParts?.forEach((p) => {
        this.exterior.push(p);
      });
      if (this.vehicle?.vehicleExternalImages?.length > 0) {
        this.vehicle?.vehicleExternalImages?.forEach((p) => {
          this.exterior.push(p);
        });
      }
      if (this.vehicle?.damagePhotos?.length > 0) {
        this.damagesDots = this.vehicle.damagePhotos;
      }
      this.vehicle?.damagePhotos?.forEach((p) => {
        this.damages.push(p);
      });
    },
    nextImg() {
      this.index++;
      this.imgNotFound = false;
      this.imgError = "";
    },
    backImg() {
      this.index--;
      this.imgNotFound = false;
      this.imgError = "";
    },
    errorLoadImg() {
      this.imgError = require("@/assets/img/image_not_found.png");
    },
    initIntervalPhotos() {
      this.intervalPhotos = setInterval(this.autoChangePhotos, 4000);
    },
    autoChangePhotos() {
      if (this.isGalleryGoingBack) {
        if (this.index === 0) {
          this.nextImg();
          this.isGalleryGoingBack = false;
        } else {
          this.backImg();
        }
      } else {
        if (this.index === this.exterior.length - 1) {
          this.backImg();
          this.isGalleryGoingBack = true;
        } else {
          this.nextImg();
        }
      }
    },
    getVehicle() {
      let vimNumber = this.$route.params.vin.split("-");
      this.$store.commit(SET_ONE_VEHICLE_FILTERS, {
        reset: true,
        ...{ fields: { vinNumber: vimNumber[0] } },
      });

      this.$store
        .dispatch(GET_VEHICLE)
        .then(() => {
          this.setImages();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the vehicle ", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    changeOfferTab(mainOfferId) {
      this.$parent.$emit("resetFilters");
      this.$store.commit(SET_VEHICLES_FILTERS, { fields: { mainOfferId } });
      this.$parent.$emit("changeTabNumber");
    },

    openCRPageModal(vehicle) {
      window.open(vehicle?.vehicleHasConditionalReports[0]?.url, "_blank");
    },
    setUserHasOffer(offer) {
      this.userHasOffer = true;
      this.currentOffer = offer;
    },
    goBack() {
      this.$router.go(-1);
    },
    editVehicle(vin) {
      this.$router.push({
        name: this.userIsAdmin
          ? "admin.vehicles.registry.general"
          : "agent.vehicles.edit.general",
        params: { vin: vin },
      });
    },
    formatImages(images) {
      return images.map((image) => {
        return {
          id: image.photo,
          src: image.photo,
          largeViewSrc: image.photo,
          thumbnail: image.photoThumbnail || image.photo,
        };
      });
    },
  },
  beforeMount() {
    this.getVehicle();
  },
  mounted() {},
};
</script>

<style scoped>
i {
  font-weight: bold !important;
}
.main-img {
  width: 100%;
  height: 25rem;
  object-fit: cover;
}
</style>
