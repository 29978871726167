<template>
  <b-row class="">
    <img
      v-if="showPixel"
      height="1"
      width="1"
      style="border-style: none"
      alt=""
      src="https://insight.adsrvr.org/track/pxl/?adv=pzdunu8&ct=0:mckzc78&fmt=3"
    />
    <b-col :cols="stacked ? 12 : null" class="">
      <a :href="formatMailTo()" v-if="userPending">
        <b-button variant="purple-blue" block class="text-pink">
          <span style="font-size: 15px">Make an Offer</span>
        </b-button>
      </a>

      <b-button
        v-else-if="!offerInput"
        variant="purple-blue"
        :disabled="userPending"
        block
        @click="showOfferInput()"
      >
        <span style="font-size: 15px" class="text-pink">Make an Offer</span>
      </b-button>

      <b-input-group v-if="offerInput" class="min-width mb-2">
        <Money
          class="form-control text-center"
          v-model="offer"
          v-bind="money"
        />
      </b-input-group>
      <div v-if="offerInput" class="mb-1 mt-1">
        <b-button
          variant="blue"
          size="sm"
          @click="sendOffer"
          :disabled="!offer || makeAnOfferStatus === 'fetching'"
        >
          <b-spinner
            variant="white"
            small
            v-if="makeAnOfferStatus === 'fetching'"
          />
          <span v-else>Send</span>
        </b-button>
        <b-button variant="danger" size="sm" @click="closeOfferInput">
          <b-icon icon="x" />
        </b-button>
      </div>
    </b-col>
    <b-col
      :cols="stacked ? 12 : null"
      v-if="!offerInput && !offer && !userPending"
      class="mt-1"
    >
      <BuyNow
        :vehicleId="vehicleId"
        :price="this.price"
        :userPending="userPending"
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from "vuex";
import { POST_VEHICLE_MAKE_AN_OFFER } from "./actions";
import BuyNow from "./BuyNow";
import { Money } from "v-money";
import { generalMixin } from "@/modules/mixin";
export default {
  components: { BuyNow, Money },
  mixins: [generalMixin],
  props: ["vehicleId", "price", "userPending", "stacked", "vehicle", "refresh"],
  computed: {
    ...mapState({
      makeAnOfferStatus: (state) =>
        state.adminStore.vehicles.listStore.componentsStore.makeAnOffer.status,
    }),
  },
  data() {
    return {
      offerInput: false,
      offer: 0.0,
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "USD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      showPixel: false,
    };
  },
  methods: {
    showOfferInput() {
      if (this.user != null) {
        this.offerInput = !this.offerInput;
      } else {
        this.$bvModal.show("login-modal");
      }
    },
    closeOfferInput() {
      this.offer = null;
      this.offerInput = false;
    },
    payload() {
      return {
        vehiclesId: this.vehicleId,
        clientId: this.user.id,
        offerPrice: this.offer,
      };
    },
    sendOffer() {
      this.showPixel = true;
      setTimeout(() => {
        this.showPixel = false;
      }, 1000);
      this.$store
        .dispatch(POST_VEHICLE_MAKE_AN_OFFER, this.payload())
        .then(() => {
          this.$emit("refresh");
          this.$root.$bvToast.toast("Offer sent", {
            title: "Success",
            variant: "success",
          });
          this.$emit("setUserHasOffer", this.offer);
          this.closeOfferInput();
        })
        .catch(() => {
          this.$root.$bvToast.toast("The offer could not be sent", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    formatMailTo() {
      return `mailto:admin@canamcar.com?subject=${this.vehicle?.vinNumber?.vinNumber}, ${this.vehicle?.vinNumber?.year}, ${this.vehicle?.vinNumber?.make}, ${this.vehicle?.vinNumber?.model}, ${this.vehicle?.vehicleInformation?.color}`;
    },
  },
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.min-width {
  min-width: 200px !important;
}
</style>
