<template>
  <b-container fluid>
    <b-navbar variant="blue" class="pt-5" />
    <Search :isMobile="isMobile" />
    <div class="mt-2 mb-2 text-right">
      <b-button variant="light" @click="editColumns()"> Edit columns </b-button>
    </div>
    <b-table
      show-empty
      bordered
      small
      stacked="md"
      head-variant="light"
      :items="vehiclesInfo.data.data"
      :fields="fields"
      :busy="vehiclesInfo.status === 'fetching'"
      :class="{ 'text-break': isMobile }"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" variant="blue" />
        </div>
      </template>

      <template v-slot:cell(vinNumber)="row">
        <span class="cursor-pointer" @click="goToDashBoard(row.item)">
          {{ row.item.vinNumber.vinNumber }}
        </span>
      </template>

      <template v-slot:cell(stockNumber)="row">
        {{ row.item.stockNumber || "No stock number assigned" }}
      </template>

      <template v-slot:cell(user)="row">
        <div v-if="agentsSelected.find((agent) => agent === row.item.id)">
          <b-input-group>
            <b-input
              list="agents"
              placeholder="Type agent name"
              @change="findAgent($event)"
            />
            <b-input-group-append>
              <b-button
                variant="outline-success"
                size="sm"
                @click="updateAgent(row.item.id)"
              >
                <b-icon icon="check" class="mt-1" font-scale="1.5" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <datalist id="agents">
            <option value="User Test" />
            <option value="User Test2" />
          </datalist>
        </div>
        <span v-else>
          <span v-if="row.item.user">
            {{ row.item.user.lastName }} {{ row.item.user.firstName }}
          </span>
          <span v-else> No agent assigned </span>
        </span>
      </template>

      <template v-slot:cell(checks)="row">
        <h3>
          <b-icon
            :icon="row.item.totalChecks ? 'credit-card-fill' : 'credit-card'"
            class="icon-color cursor-pointer"
            @click="openChecksModal(row.item)"
          />
        </h3>
      </template>
      <template v-slot:cell(conditionalReports)="row">
        <h3>
          <b-icon
            :icon="
              row.item.totalConditionalReports
                ? 'file-earmark-fill'
                : 'file-earmark'
            "
            class="icon-color cursor-pointer"
            @click="openConditionalReportsModal(row.item)"
          />
        </h3>
      </template>
      <template v-slot:cell(notes)="row">
        <img
          v-if="row.item.totalNotes"
          src="@/assets/img/icons/u90-info.png"
          class="d-inline-block align-center temp-icon cursor-pointer"
          @click="openNotesModal(row.item)"
        />
        <img
          v-else
          src="@/assets/img/icons/u90.png"
          class="d-inline-block align-center temp-icon cursor-pointer"
          @click="openNotesModal(row.item)"
        />
      </template>
      <template v-slot:cell(recall)>
        <h4>
          <b-icon icon="eye" class="icon-color cursor-pointer" />
        </h4>
      </template>
      <template v-slot:cell(carfax)>
        <h4>
          <b-icon icon="eye" class="icon-color cursor-pointer" />
        </h4>
      </template>
      <template v-slot:cell(exportLogistic)>
        <h4>
          <b-icon icon="eye" class="icon-color cursor-pointer" />
        </h4>
      </template>
      <template v-slot:cell(exportPaperwork)>
        <h4>
          <b-icon icon="eye" class="icon-color cursor-pointer" />
        </h4>
      </template>
      <template v-slot:cell(registry)>
        <h4>
          <b-icon icon="eye" class="icon-color cursor-pointer" />
        </h4>
      </template>
      <template v-slot:cell(sold)>
        <h4>
          <b-icon icon="eye" class="icon-color cursor-pointer" />
        </h4>
      </template>
      <template v-slot:cell(visualInspection)>
        <h4>
          <b-icon icon="eye" class="icon-color cursor-pointer" />
        </h4>
      </template>
    </b-table>
    <div style="float: right">
      <b-pagination
        :value="vehiclesInfo.data.currentPage"
        :total-rows="vehiclesInfo.data.total"
        :per-page="vehiclesInfo.data.perPage"
        @change="changePage($event)"
        class="mb-2"
      />
    </div>

    <SelectTableFields
      :fieldsFromTable="this.fields"
      @getCookie="getCookie()"
    />
    <ChecksModal />
    <ConditionalReportsModal />
    <NotesModal />
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import { defaultFields, optionalFields } from "./vehicleTableFields";
import Search from "./components/Search";
import { superAdminMixin } from "./mixins";
import ChecksModal from "./components/checks/ChecksModal";
import ConditionalReportsModal from "./components/conditionalReports/ConditionalReportsModal";
import NotesModal from "./components/notes/NotesModal";
import SelectTableFields from "./components/SelectVehicleTableFields";

export default {
  mixins: [superAdminMixin],
  components: {
    Search,
    SelectTableFields,
    ChecksModal,
    ConditionalReportsModal,
    NotesModal,
  },
  data() {
    return {
      fields: [],
      agentsSelected: [],
    };
  },
  computed: {
    ...mapState({
      vehiclesInfo: (state) => state.superAdminStore.vehiclesInfo,
    }),
    isMobile() {
      return this.$vssWidth <= 576 ? true : false;
    },
  },
  methods: {
    findAgent(name) {
      console.log("Find agent with name:", name);
    },
    selectAgent(agent) {
      this.agentsSelected.push(agent);
    },
    updateAgent(agent) {
      const index = this.agentsSelected.indexOf(agent);
      this.agentsSelected.splice(index, 1);
    },
    editColumns() {
      this.$bvModal.show("select-vehicle-table-fields");
    },
    getCookie() {
      this.fields = JSON.parse(this.$cookies.get("fields"));
    },
    saveCookie() {
      if (!this.$cookies.isKey("fields")) {
        this.fields = [...defaultFields, ...optionalFields];
        this.$cookies.set(
          "fields",
          JSON.stringify([...defaultFields, ...optionalFields])
        );
      } else {
        this.getCookie();
      }
    },
  },
  mounted() {
    this.resetFilters();
    this.getVehiclesInfo();
    this.saveCookie();
  },
};
</script>

<style>
.icon-color {
  color: #aeaeaf;
}
</style>
