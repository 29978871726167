<template>
  <b-container fluid v-if="vehicle">
    <b-navbar variant="blue" class="pt-5" />
    <b-container class="text-center mt-4">
      <b-card style="border-color: #12144a">
        <b-card-text>
          <b>{{ vehicle.vinNumber.vinNumber }}</b>
        </b-card-text>
      </b-card>
    </b-container>
    <b-row class="mt-4">
      <b-col xl="3" lg="3" sm="6" class="mb-2">
        <b-card
          class="h-100 cursor-pointer"
          style="border-color: #12144a"
          @click="showVehicleRegistration()"
          :class="{ shadow: sectionToShow === 'Vehicle Registration' }"
        >
          <b-card-text class="text-center">
            <h1>
              <b-icon icon="plus" variant="success" />
            </h1>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col xl="3" lg="3" sm="6" class="mb-2">
        <b-card
          class="h-100 cursor-pointer"
          style="border-color: #12144a"
          :class="{ shadow: sectionToShow === 'Checks' }"
          @click="showChecksList()"
        >
          <b-card-text>
            <div class="media d-flex mt-2">
              <div class="align-self-center">
                <img
                  src="@/assets/img/icons/check_white.png"
                  class="d-inline-block align-center temp-icon"
                />
              </div>
              <div class="media-body text-right mt-2">
                {{ vehicle.totalChecks || "No" }} Checks
              </div>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col xl="3" lg="3" sm="6" class="mb-2">
        <b-card
          class="h-100 cursor-pointer"
          style="border-color: #12144a"
          :class="{ shadow: sectionToShow === 'Conditional Reports' }"
          @click="showConditionalReportsList()"
        >
          <b-card-text>
            <div class="media d-flex mt-2">
              <div class="align-self-center">
                <h3>
                  <b-icon icon="file-earmark" class="icon-color" />
                </h3>
              </div>
              <div class="media-body text-right mt-2">
                {{ vehicle.totalConditionalReports || "No" }} Conditional
                Reports
              </div>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col xl="3" lg="3" sm="6" class="mb-2">
        <b-card
          class="h-100 cursor-pointer"
          style="border-color: #12144a"
          :class="{ shadow: sectionToShow === 'Notes' }"
          @click="showNotesList()"
        >
          <b-card-text>
            <div class="media d-flex mt-2">
              <div class="align-self-center">
                <img
                  src="@/assets/img/icons/u90.png"
                  class="d-inline-block align-center temp-icon"
                />
              </div>
              <div class="media-body text-right mt-2">
                {{ vehicle.totalNotes || "No" }} Notes
              </div>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <hr />
    <!-- Components -->
    <div
      v-if="sectionToShow === 'Vehicle Registration'"
      class="upload-vehicle mb-5"
    >
      <SearchVehicleByVin
        :showProgressBar="false"
        :showUploadVehiclesFile="false"
        :redirectToTable="true"
        :closeUploadVehicleModal="false"
      />
    </div>
    <b-row
      v-else-if="
        checks.status === 'fetching' ||
        conditionalReports.status === 'fetching' ||
        notes.status === 'fetching'
      "
    >
      <b-col class="text-center">
        <b-spinner variant="blue" />
      </b-col>
    </b-row>
    <div v-else-if="sectionToShow === 'Checks' && checks.status === 'success'">
      <div class="text-right mb-2">
        <b-button
          :variant="showCheckRegistrationForm ? 'outline-danger' : 'success'"
          @click="showCheckForm()"
        >
          <b-icon :icon="showCheckRegistrationForm ? 'x' : 'plus'" />
        </b-button>
      </div>
      <b-container v-if="showCheckRegistrationForm" class="mb-3">
        <CheckRegistrationForm :vehicle="vehicle" ref="CheckRegistrationForm" />
      </b-container>
      <b-row>
        <b-col
          xl="4"
          lg="4"
          sm="6"
          class="mb-2"
          v-for="(check, index) in checks.data.data"
          :key="index"
        >
          <CheckCard :check="check" />
        </b-col>
      </b-row>
    </div>
    <div
      v-else-if="
        sectionToShow === 'Conditional Reports' &&
        conditionalReports.status === 'success'
      "
    >
      <div class="text-right mb-2">
        <b-button
          :variant="showCRRegistrationForm ? 'outline-danger' : 'success'"
          @click="showCRForm()"
        >
          <b-icon :icon="showCRRegistrationForm ? 'x' : 'plus'" />
        </b-button>
      </div>
      <b-container v-if="showCRRegistrationForm" class="mb-3">
        <CRRegistrationForm :vehicle="vehicle" ref="CRRegistrationForm" />
      </b-container>
      <b-row>
        <b-col
          xl="4"
          lg="4"
          sm="6"
          class="mb-2"
          v-for="(cr, index) in conditionalReports.data.data"
          :key="index"
        >
          <ConditionalReportCard :conditionalReport="cr" />
        </b-col>
      </b-row>
    </div>
    <div v-else-if="sectionToShow === 'Notes' && notes.status === 'success'">
      <div class="text-right mb-2">
        <b-button
          :variant="showNoteRegistrationForm ? 'outline-danger' : 'success'"
          @click="showNoteForm()"
        >
          <b-icon :icon="showNoteRegistrationForm ? 'x' : 'plus'" />
        </b-button>
      </div>
      <b-container v-if="showNoteRegistrationForm" class="mb-3">
        <NoteRegistrationForm :vehicle="vehicle" ref="NoteRegistrationForm" />
      </b-container>
      <b-row>
        <b-col
          xl="4"
          lg="4"
          sm="6"
          class="mb-2"
          v-for="(note, index) in notes.data.data"
          :key="index"
        >
          <NoteCard :note="note" />
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import { superAdminMixin } from "./mixins";
import CheckCard from "./components/checks/CheckCard";
import CheckRegistrationForm from "./components/checks/RegistrationForm";
import ConditionalReportCard from "./components/conditionalReports/ConditionalReportCard";
import CRRegistrationForm from "./components/conditionalReports/RegistrationForm";
import NoteCard from "./components/notes/NoteCard";
import NoteRegistrationForm from "./components/notes/RegistrationForm";
import SearchVehicleByVin from "@/modules/admin/vehicles/registry/SearchVehicleByVin";
export default {
  components: {
    SearchVehicleByVin,
    CheckCard,
    CheckRegistrationForm,
    ConditionalReportCard,
    CRRegistrationForm,
    NoteCard,
    NoteRegistrationForm,
  },
  mixins: [superAdminMixin],
  data() {
    return {
      sectionToShow: "Checks",
      showCheckRegistrationForm: true,
      showCRRegistrationForm: false,
      showNoteRegistrationForm: false,
    };
  },
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
      checks: (state) => state.superAdminStore.componentStore.checks,
      conditionalReports: (state) =>
        state.superAdminStore.componentStore.conditionalReports,
      notes: (state) => state.superAdminStore.componentStore.notes,
    }),
  },
  methods: {
    searchVehicleInfo() {
      this.search(this.vehicle.vinNumber.vinNumber);
    },
    showVehicleRegistration() {
      this.sectionToShow = "Vehicle Registration";
    },
    showChecksList() {
      this.sectionToShow = "Checks";
      this.showCheckRegistrationForm = false;
      this.getChecks(false, this.vehicle);
    },
    showCheckForm() {
      this.showCheckRegistrationForm = !this.showCheckRegistrationForm;
      if (!this.showCheckRegistrationForm)
        this.$refs.CheckRegistrationForm.resetForm();
    },
    showConditionalReportsList() {
      this.sectionToShow = "Conditional Reports";
      this.showCRRegistrationForm = false;
      this.getConditionalReports(false, this.vehicle);
    },
    showCRForm() {
      this.showCRRegistrationForm = !this.showCRRegistrationForm;
      if (!this.showCRRegistrationForm)
        this.$refs.CRRegistrationForm.resetForm();
    },
    showNotesList() {
      this.sectionToShow = "Notes";
      this.showNoteRegistrationForm = false;
      this.getNotes(true, this.vehicle);
    },
    showNoteForm() {
      this.showNoteRegistrationForm = !this.showNoteRegistrationForm;
      if (!this.showNoteRegistrationForm)
        this.$refs.NoteRegistrationForm.resetForm();
    },
  },
  beforeMount() {
    if (!this.vehicle) {
      this.$router.push({ name: "superadmin.vehicles.table" });
    } else {
      this.searchVehicleInfo();
      this.showCheckRegistrationForm = false;
      this.getChecks(true, this.vehicle);
    }
  },
};
</script>

<style>
.icon-color {
  color: #aeaeaf;
}
.cursor-pointer {
  cursor: pointer;
}
.panel-border-color {
  border-color: black;
}
@media (min-width: 600px) {
  .upload-vehicle {
    margin-left: 32%;
  }
}
</style>
