<template>
  <b-row>
    <b-col>
      <b-progress
        :variant="complete ? 'success' : 'blue'"
        :max="max"
        height="2rem"
      >
        <b-progress-bar
          :animated="isPassed(1) && keepAnimated"
          :variant="isPassed(1) ? 'blue' : 'white'"
          :class="{ 'border-blue': !isPassed(1) }"
          :value="1"
          class="ml-5 mr-5 step step-1"
        >
          <span v-if="step === 1">
            <strong>{{ step }} / {{ max }}</strong>
          </span>
        </b-progress-bar>
        <b-progress-bar
          :variant="isPassed(2) ? 'pink' : 'white'"
          :class="{ 'border-pink': !isPassed(2) }"
          :value="1"
          :animated="isPassed(2) && keepAnimated"
          class="mr-5 step"
        >
          <span v-if="step === 2">
            <strong>{{ step }} / {{ max }}</strong>
          </span>
        </b-progress-bar>
        <b-progress-bar
          :animated="isPassed(3) && keepAnimated"
          :variant="isPassed(3) ? 'blue' : 'white'"
          :class="{ 'border-blue': !isPassed(3) }"
          :value="1"
          class="mr-5 step"
        >
          <span v-if="step === 3">
            <strong>{{ step }} / {{ max }}</strong>
          </span>
        </b-progress-bar>
      </b-progress>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    step: Number,
    complete: Boolean,
  },
  data() {
    return {
      max: 3,
      keepAnimated: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.keepAnimated = false;
    }, 1000);
  },
  methods: {
    isPassed(value) {
      return this.step >= value;
    },
  },
};
</script>

<style lang="scss">
.border-pink {
  border: solid 2px #df0632;
}
.border-blue {
  border: solid 2px #35a4dd;
}
@media (max-width: 300px) {
  .step {
    margin-left: -2rem;
    padding-right: 40px;

    span {
      margin-left: 8px;
    }
  }
}
</style>
