export const GET_COMPANIES_LIST = "GET_COMPANIES_LIST";
export const GET_COMPANIES_LIST_SUCCESS = "GET_COMPANIES_LIST_SUCCESS";
export const GET_COMPANIES_LIST_ERROR = "GET_COMPANIES_LIST_ERROR";

export const GET_USER_TO_ASSIGN_COMPANY = "GET_USER_TO_ASSIGN_COMPANY";
export const GET_USER_TO_ASSIGN_COMPANY_SUCCESS =
  "GET_USER_TO_ASSIGN_COMPANY_SUCCESS";
export const GET_USER_TO_ASSIGN_COMPANY_ERROR =
  "GET_USER_TO_ASSIGN_COMPANY_ERROR";

export const PUT_ASSIGN_COMPANY = "PUT_ASSIGN_COMPANY";
export const PUT_ASSIGN_COMPANY_SUCCESS = "PUT_ASSIGN_COMPANY_SUCCESS";
export const PUT_ASSIGN_COMPANY_ERROR = "PUT_ASSIGN_COMPANY_ERROR";

export const POST_CLIENT = "POST_CLIENT";
export const POST_CLIENT_SUCCESS = "POST_CLIENT_SUCCESS";
export const POST_CLIENT_ERROR = "POST_CLIENT_ERROR";

export const PUT_REJECT_COMPANY = "PUT_REJECT_COMPANY";
export const PUT_REJECT_COMPANY_SUCCESS = "PUT_REJECT_COMPANY_SUCCESS";
export const PUT_REJECT_COMPANY_ERROR = "PUT_REJECT_COMPANY_ERROR";

export const ADD_NEW_COMPANY = "ADD_NEW_COMPANY";

export const GET_COMPANY_LIST_AND_PRE = "GET_COMPANY_LIST_AND_PRE";
export const GET_COMPANY_LIST_AND_PRE_SUCCESS =
  "GET_COMPANY_LIST_AND_PRE_SUCCESS";
export const GET_COMPANY_LIST_AND_PRE_ERROR = "GET_COMPANY_LIST_AND_PRE_ERROR";

export const POST_SEND_EMAIL_COMPANY_FORM = "POST_SEND_EMAIL_COMPANY_FORM";
export const POST_SEND_EMAIL_COMPANY_FORM_SUCCESS =
  "POST_SEND_EMAIL_COMPANY_FORM_SUCCESS";
export const POST_SEND_EMAIL_COMPANY_FORM_ERROR =
  "POST_SEND_EMAIL_COMPANY_FORM_ERROR";