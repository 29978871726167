<template>
  <div>
    <img
      v-if="showPixel"
      height="1"
      width="1"
      style="border-style: none"
      alt=""
      src="https://insight.adsrvr.org/track/pxl/?adv=pzdunu8&ct=0:vr3oc3f&fmt=3"
    />

    <div class="container">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="2" cols="12"></b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="8" cols="12">
          <b-form class="m-4" @submit.stop.prevent="save">
            <h2 class="text-purple-blue">Sign up</h2>
            <b-row>
              <b-col sm="12" md="6" lg="6" xl="6">
                <!-- Email -->
                <b-form-group label-size="sm">
                  <template slot="label">
                    Email <span class="text-danger"> *</span>
                  </template>
                  <b-form-input
                    size="sm"
                    id="email-input"
                    name="email-input"
                    :formatter="lowerCaseFormatter"
                    @change="setValue('email', $event)"
                    :value="form.email"
                    v-model.trim="$v.form.email.$model"
                    :state="validateState('email')"
                    aria-describedby="input-email-live-feedback"
                    maxlength="255"
                  >
                  </b-form-input>
                  <b-form-invalid-feedback id="input-email-live-feedback">
                    This is a required field and must be a valid email.
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- Auction access number -->

              <!--               <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                <b-form-group label-size="sm">
                  <template slot="label"> Auction Access Number </template>
                  <b-form-input
                    size="md"
                    v-model="$v.form.auctionAccessNumber.$model"
                    :state="validateState('auctionAccessNumber')"
                    :value="form.auctionAccessNumber"
                    @change="setValue('auctionAccessNumber', $event)"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col> -->
              <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                <!-- First name -->
                <b-form-group label-size="sm">
                  <template slot="label">
                    First name <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    size="sm"
                    id="firstName-input"
                    name="firstName-input"
                    @change="setValue('name', $event)"
                    :value="form.name"
                    v-model.trim="$v.form.name.$model"
                    :state="validateState('name')"
                    aria-describedby="input-firstName-live-feedback"
                    maxlength="100"
                  >
                  </b-form-input>
                  <b-form-invalid-feedback id="input-firstName-live-feedback">
                    This is a required field and must at least 2 characters.
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <!-- Last name -->
                <b-form-group label-size="sm">
                  <template slot="label"
                    >Last name <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    size="sm"
                    id="lastName-input"
                    name="lastName-input"
                    @change="setValue('lastName', $event)"
                    :value="form.lastName"
                    v-model.trim="$v.form.lastName.$model"
                    :state="validateState('lastName')"
                    aria-describedby="input-lastName-live-feedback"
                    maxlength="100"
                  >
                  </b-form-input>
                  <b-form-invalid-feedback id="input-lastName-live-feedback">
                    This is a required field and must at least 2 characters.
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="6" lg="6" xl="6">
                <!-- Office phone number -->
                <b-form-group label-size="sm">
                  <template slot="label">
                    Office phone number <span class="text-danger">*</span>
                  </template>
                  <b-input-group>
                    <vue-tel-input
                      defaultCountry="us"
                      :inputOptions="options"
                      :dropdownOptions="telDdOptions"
                      :autoFormat="true"
                      :validCharactersOnly="true"
                      mode="international"
                      styleClasses="z-index-2"
                      @change="setValue('officePhoneNumber', $event)"
                      v-model.trim="$v.form.officePhoneNumber.$model"
                      :value="form.officePhoneNumber"
                      class="w-75"
                    />
                    <b-form-input
                      size="md"
                      type="tel"
                      maxlength="6"
                      max="999999"
                      onkeydown="javascript: return event.keyCode == 69 ? false : true"
                      class="append-input"
                      id="extension-input"
                      placeholder="ext"
                      name="extension-input"
                      @change="setValue('extension', $event)"
                      :value="form.extension"
                      v-model.trim="$v.form.extension.$model"
                      :state="validateState('extension')"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback> </b-form-invalid-feedback>
                    <b-form-invalid-feedback>
                      This is a required field
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6" lg="6" xl="6">
                <!-- Mobile phone -->
                <b-form-group label-size="sm" label="Mobile phone">
                  <template slot="label"> Mobile phone </template>
                  <vue-tel-input
                    v-model="form.mobilePhone"
                    defaultCountry="us"
                    :inputOptions="options"
                    :dropdownOptions="telDdOptions"
                    :autoFormat="true"
                    :validCharactersOnly="true"
                    @change="
                      setValue('mobilePhone', $event);
                      handleMobilePhoneChange();
                    "
                    :value="form.mobilePhone"
                    mode="international"
                    styleClasses="z-index-2"
                  />

                  <b-form-invalid-feedback
                    id="input-mobile-phone-live-feedback"
                  >
                    This is a numeric field
                  </b-form-invalid-feedback>
                  <!--    <b-alert show variant="info" class="mt-2 text-justify">
                    <small>
                      Mobile Phone will be used for notifications.
                      <br />
                      You may opt-out from receiving SMS messages at any time by
                      removing your mobile phone from your profile.
                    </small>
                  </b-alert> -->
                </b-form-group>
              </b-col>
              <b-col sm="12" md="12" lg="6" xl="6" class="mb-2"> </b-col>

              <b-col sm="12" md="12" lg="6" xl="6" class="mb-2">
                <b-row class="mb-2">
                  <b-col>
                    Password
                    <span class="text-danger">*</span>
                  </b-col>
                </b-row>
                <b-input-group>
                  <b-input
                    size="sm"
                    :type="showPassword ? 'text' : 'password'"
                    name="password-input"
                    :value="form.password"
                    v-model.trim="$v.form.password.$model"
                    :state="validateState('password')"
                    @change="setValue('password', $event)"
                  />
                  <b-input-group-append>
                    <b-button
                      v-if="showPassword == false"
                      variant="outline-primary"
                      size="sm"
                      @click="hanldePasswordVisibility('showPassword', true)"
                    >
                      <b-icon icon="eye" class="mt-1" font-scale="0.99" />
                    </b-button>
                    <b-button
                      v-else
                      variant="outline-primary"
                      size="sm"
                      @click="hanldePasswordVisibility('showPassword', false)"
                    >
                      <b-icon icon="eye-slash" class="" font-scale="0.99" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>

                <small
                  v-bind:class="{
                    'text-danger': !$v.form.password.required,
                    'text-success': !(
                      !$v.form.password.required & !$v.form.password.$dirty
                    ),
                  }"
                >
                  This field is required

                  <br />
                </small>
                <small
                  v-bind:class="{
                    'text-success': minLenght,

                    'text-danger': !minLenght,
                  }"
                >
                  This field must have at least eight characters

                  <br />
                </small>
                <small
                  v-bind:class="{
                    'text-danger': !$v.form.password.containsUppercase,
                    'text-success': !(
                      !$v.form.password.containsUppercase &
                      !$v.form.password.$dirty
                    ),
                  }"
                >
                  Must have at least one uppercase
                  <br />
                </small>
                <small
                  v-bind:class="{
                    'text-danger': !$v.form.password.containsLowercase,
                    'text-success': !(
                      !$v.form.password.containsLowercase &
                      !$v.form.password.$dirty
                    ),
                  }"
                >
                  Must have at least one lowercase
                  <br />
                </small>
                <small
                  v-bind:class="{
                    'text-danger': !$v.form.password.containsNumber,
                    'text-success': !(
                      !$v.form.password.containsNumber &
                      !$v.form.password.$dirty
                    ),
                  }"
                >
                  Must have at least one number
                  <br />
                </small>
                <small
                  v-bind:class="{
                    'text-danger': !$v.form.password.containsSpecial,
                    'text-success': !(
                      !$v.form.password.containsSpecial &
                      !$v.form.password.$dirty
                    ),
                  }"
                >
                  Must have at least one special character
                  <br />
                </small>
              </b-col>
              <b-col sm="12" md="12" lg="6" xl="6">
                <b-row class="mb-2">
                  <b-col>
                    Confirmation password
                    <span class="text-danger">*</span>
                  </b-col>
                </b-row>
                <b-input-group label-size="md">
                  <b-input
                    required
                    size="sm"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    autocomplete="off"
                    name="confirmPassword"
                    :value="form.confirmPassword"
                    v-model.trim="$v.form.confirmPassword.$model"
                    :state="validateState('confirmPassword')"
                    @change="setValue('confirmPassword', $event)"
                  />

                  <b-input-group-append>
                    <b-button
                      v-if="showConfirmPassword == false"
                      variant="outline-primary"
                      size="sm"
                      @click="
                        hanldePasswordVisibility('showConfirmPassword', true)
                      "
                    >
                      <b-icon icon="eye" class="mt-1" font-scale="0.99" />
                    </b-button>
                    <b-button
                      v-else
                      variant="outline-primary"
                      size="sm"
                      @click="
                        hanldePasswordVisibility('showConfirmPassword', false)
                      "
                    >
                      <b-icon icon="eye-slash" class="" font-scale="0.99" />
                    </b-button>
                  </b-input-group-append>
                  <b-form-invalid-feedback>
                    <div v-if="!$v.form.password.sameAsPassword">
                      Passwords do not match
                    </div>
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row> </b-row>
            <b-row>
              <b-col sm="12" md="6" lg="12" xl="12">
                <b-form-checkbox
                  id="checkbox-3"
                  class="z-index-0 mb-2"
                  v-model="isAccepTerms"
                  name="checkbox-3"
                  :value="true"
                  :unchecked-value="false"
                >
                  I accept the
                  <a href="#" v-b-modal.terms-modal> terms and conditions</a>
                  and I have read the
                  <a href="#" v-b-modal.messaging-policy-modal>
                    Messaging Policy
                  </a>
                </b-form-checkbox>
                <b-form-checkbox
                  id="checkbox-1"
                  class="z-index-0 mb-2"
                  v-model="isAcceptEmailNotifications"
                  name="checkbox-2"
                  :value="true"
                  :unchecked-value="false"
                >
                  I wish to receive notifications via Email from
                  <a href="https://canamcar.com/#/">Canamcar.com</a>
                </b-form-checkbox>
                <b-form-checkbox
                  :disabled="form.mobilePhone.length < 12"
                  id="checkbox-2"
                  class="z-index-0"
                  v-model="isAcceptSmsNotifications"
                  name="checkbox-2"
                  :value="true"
                  :unchecked-value="false"
                >
                  I wish to receive notifications via SMS from
                  <a href="https://canamcar.com/#/">Canamcar.com</a>
                </b-form-checkbox>
              </b-col>
              <b-col sm="12" md="3" lg="3" xl="3"></b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col sm="12" md="4" lg="4" xl="4"></b-col>
              <b-col sm="12" md="4" lg="4" xl="4">
                <b-btn
                  block
                  size="lg"
                  class="btn btn-success"
                  :disabled="
                    $v.form.$invalid ||
                    isLoading ||
                    !isAccepTerms ||
                    (!isAcceptEmailNotifications && !isAcceptSmsNotifications)
                  "
                  type="submit"
                >
                  <b-spinner v-if="isLoading" small variant="white" />
                  <span v-else>Submit</span>
                </b-btn>
              </b-col>
              <b-col sm="12" md="4" lg="4" xl="4"></b-col>
            </b-row>
            <b-row class="mt-3">
              <p class="text-justify">
                <small style="color: gray">
                  By clicking “Submit”, you agree to Canamcar.com and/or Canam
                  Auto Terms of Use and Privacy Policy. You consent to receive
                  phone calls and SMS messages from Canamcar.com and/or Canam
                  Auto to provide updates on your order and/or for marketing
                  purposes. Message frequency depends on your activity. You may
                  opt-out by texting “STOP”. Message and data rates may apply.
                </small>
              </p>
            </b-row>

            <b-row>
              <b-col class="text-center">
                <br />
                <hr />
                <small style="color: gray">
                  CANAM AUTO 4266 DOVE RD. SUITE C PORT HURON 48060 MICHIGAN
                  <a href="tel: +1 833 396 1906"> +1 833 396 1906 </a>
                </small>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="2" cols="12" class="" />
      </b-row>

      <b-modal
        centered
        ref="emailModal"
        id="emailModal"
        size="md"
        hide-header-close
        hide-header
        hide-footer
        body-class="pl-0 pr-0"
      >
        <div class="text-center p-5">
          <p>
            <b-icon font-scale="5" icon="envelope"></b-icon>
          </p>
          <h5>We sent a confirmation link to the email address entered</h5>
          <b-btn
            size="lg"
            class="btn-main mt-2"
            @click="
              $bvModal.hide('emailModal');
              goTo();
            "
          >
            Accept
          </b-btn>
        </div>
      </b-modal>

      <TermsAndConditions />
      <MessagingPolicy />
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  email,
  numeric,
  sameAs,
} from "vuelidate/lib/validators";
import { POST_EMAIL_VALIDATION, POST_USER } from "./actions";
import { mapState } from "vuex";
import TermsAndConditions from "./components/TermsAndConditions";
import MessagingPolicy from "./components/MessagingPolicy";

export default {
  components: { TermsAndConditions, MessagingPolicy },
  computed: {
    ...mapState({
      emailValidation: (state) => state.buyer.buyerStore.emailValidation.data,
      user: (state) => state.buyer.buyerStore.user,
    }),
    isLoading() {
      return this.user.status == "fetching" ? true : false;
    },
    mobileNumberHandle() {
      return this.form.mobilePhone;
    },
    minLenght() {
      return this.form.password.length > 7 ? true : false;
    },
  },
  watch: {
    mobileNumberHandle: function () {
      if (this.mobileNumberHandle == "") {
        this.isAcceptSmsNotifications = false;
      }
    },
  },
  data() {
    return {
      status: false,
      isAccepTerms: false,
      isAcceptEmailNotifications: true,
      isAcceptSmsNotifications: true,
      form: {
        name: null,
        lastName: null,
        email: null,
        officePhoneNumber: null,
        extension: null,
        mobilePhone: "",
        companyName: null,
        password: "",
        confirmPassword: "",
        /*         auctionAccessNumber: null,
         */
      },
      options: {
        type: "tel",
        validCharactersOnly: true,
        maxlength: 14,
      },
      telDdOptions: {
        showDialCodeInList: true,
        showFlags: true,
        showDialCodeInSelection: false,
      },
      showPassword: false,
      showConfirmPassword: false,
      showPixel: false,
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        containsUppercase: (value) => /[A-Z]/.test(value),
        containsLowercase: (value) => /[a-z]/.test(value),
        containsNumber: (value) => /[0-9]/.test(value),
        containsSpecial: (value) => /[#?!@$%^&*-]/.test(value),
      },
      confirmPassword: {
        sameAsPassword: sameAs("password"),
      },
      name: {
        required,
        minLength: minLength(2),
      },
      lastName: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
      extension: {
        numeric,
      },
      officePhoneNumber: {
        required,
        minLength: minLength(10),
      },
      /*       auctionAccessNumber: {},
       */
    },
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async save() {
      await this.verifyEmail();
      this.showPixel = true;
      setTimeout(() => {
        this.showPixel = false;
      }, 1000);
      if (this.emailValidation.message === "invalid email") {
        return;
      }
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_USER, payload)
        .then(() => {
          this.$refs["emailModal"].show();
        })
        .catch(() => {
          this.$root.$bvToast.toast(this.user.error.response.data.message, {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    async verifyEmail() {
      await this.$store
        .dispatch(POST_EMAIL_VALIDATION, this.form)
        .then(() => {
          if (this.emailValidation.message === "invalid email") {
            this.$root.$bvToast.toast("This email has already been taken", {
              title: "Warning",
              variant: "warning",
            });
          }
        })
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    goTo() {
      this.$router.push({ path: "/" });
    },
    formatPayload() {
      let role = 3; // ROLE_CLIENT

      const payload = {
        firstName: this.form.name,
        lastName: this.form.lastName,
        email: this.form.email,
        rolesId: [role],
        userDetails: {
          extension: this.form.extension,
          mobileNumber: this.form.mobilePhone,
          officePhoneNumber: this.form.officePhoneNumber,
          acceptEmail: this.isAcceptEmailNotifications,
          acceptSms: this.isAcceptSmsNotifications,
          /*           auctionAccessNumber: this.form?.auctionAccessNumber,
           */
        },

        password: this.form.password,
      };

      return payload;
    },
    hanldePasswordVisibility(input, isShow) {
      input === "showPassword"
        ? (this.showPassword = isShow)
        : (this.showConfirmPassword = isShow);
    },
  },
  mounted() {},
};
</script>

<style scoped>
.append-input {
  max-width: 40%;
}
</style>
